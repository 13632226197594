import React, { useState } from 'react';

const Admin = () => {
  const [basicRate, setBasicRate] = useState(0);
  const [allRate, setAllRate] = useState(0);
  const [validityPeriodDays, setValidityPeriodDays] = useState(0);
  const [maintenanceRate, setMaintenanceRate] = useState(0);
  const [value, setValue] = useState(50);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const days = [
    {
      day: 'Mon',
      bg: 'white',
      radius: 'rounded-tl-[8px] rounded-bl-[8px] border-[1px] border-[#F2F2F2]',
      size: 'text-[12px] leading-[14.4px]',
    },
    {
      day: 'Tues',
      bg: 'white',
      radius: 'border-[1px] border-[#F2F2F2]',
      size: 'text-[12px] leading-[14.4px]',
    },
    {
      day: 'Wed',
      bg: 'white',
      radius: 'border-[1px] border-[#F2F2F2]',
      size: 'text-[12px] leading-[14.4px]',
    },
    {
      day: 'Thurs',
      bg: 'white',
      radius: 'border-[1px] border-[#F2F2F2]',
      size: 'text-[12px] leading-[14.4px]',
    },
    {
      day: 'Fri',
      bg: 'white',
      radius: 'border-[1px] border-[#F2F2F2]',
      size: 'text-[12px] leading-[14.4px]',
    },
    {
      day: 'Sat',
      bg: '',
      radius: '',
      size: 'text-[14px] leading-[21px]',
    },
    {
      day: 'Sun',
      bg: '',
      radius: '',
      size: 'text-[14px] leading-[21px]',
    },
  ];
  return (
    <div className="flex flex-col justify-start items-center space-y-[30px]">
      <div className="flex justify-between items-center gap-[16px] w-[1247px] h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[684px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Admin
        </h1>
      </div>
      <div className="w-auto h-auto flex flex-col justify-start items-start space-y-[30px] mr-[70px] pb-[30px]">
        {/* 1st Row */}
        <div className="flex justify-start items-center gap-[16px]">
          <div className="w-[363px] h-[348px] rounded-[16px] border-[1px] border-[#F2F2F2] p-[32px] flex flex-col justify-start items-start space-y-[16px]">
            <div className="flex flex-col justify-start items-start space-y-[4px] w-[299px] h-[43px]">
              <h1 className="font-albert font-bold text-[18px] leading-[21.2px] text-[#0F153E]">
                Standard Energy Costs
              </h1>
              <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">For new quotes</p>
            </div>
            <div className="flex flex-col justify-start items-start space-y-[32px] w-[299px] h-[179px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[299px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                  Energy costs Basic Rate (€)
                </p>
                <div className="w-[299px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[243px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[299px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                  Energy costs All-In Rate (€)
                </p>
                <div className="w-[299px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[243px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={allRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setAllRate(allRate + 1)}
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setAllRate(allRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <button className="w-[75px] h-[30px] font-albert font-bold text-[12px] leading-[14.2px] text-[#FFFFFF] rounded-[8px] py-[8px] px-[24px] flex justify-start items-start gap-[4px] bg-[#9BA1CF]">
              Save
            </button>
          </div>
          <div className="w-[363px] h-[348px] rounded-[16px] border-[1px] border-[#F2F2F2] p-[32px] flex flex-col justify-start items-start space-y-[16px]">
            <div className="flex flex-col justify-start items-start space-y-[4px] w-[299px] h-[65px]">
              <h1 className="font-albert font-bold text-[18px] leading-[21.2px] text-[#0F153E]">
                Standard Validity Period Of A Quotation
              </h1>
              <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                For new or duplicate quotes
              </p>
            </div>
            <div className="flex flex-col justify-start items-start space-y-[32px] w-[299px] h-[179px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[299px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                  Validity period in days
                </p>
                <div className="w-[299px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[243px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={validityPeriodDays}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setValidityPeriodDays(validityPeriodDays + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setValidityPeriodDays(validityPeriodDays - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <button className="w-[75px] h-[30px] font-albert font-bold text-[12px] leading-[14.2px] text-[#FFFFFF] rounded-[8px] py-[8px] px-[24px] flex justify-start items-start gap-[4px] bg-[#9BA1CF]">
              Save
            </button>
          </div>
          <div className="w-[363px] h-[348px] rounded-[16px] border-[1px] border-[#F2F2F2] p-[32px] flex flex-col justify-start items-start space-y-[16px]">
            <div className="flex flex-col justify-start items-start space-y-[4px] w-[299px] h-[65px]">
              <h1 className="font-albert font-bold text-[18px] leading-[21.2px] text-[#0F153E]">Maintenance Rate</h1>
              <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                Included in a recalculation/adjustment of a quotation.
              </p>
            </div>
            <div className="flex flex-col justify-start items-start space-y-[32px] w-[299px] h-[179px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[299px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Maintenance rate</p>
                <div className="w-[299px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[243px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={validityPeriodDays}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setMaintenanceRate(maintenanceRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setMaintenanceRate(maintenanceRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <button className="w-[75px] h-[30px] font-albert font-bold text-[12px] leading-[14.2px] text-[#FFFFFF] rounded-[8px] py-[8px] px-[24px] flex justify-start items-start gap-[4px] bg-[#9BA1CF]">
              Save
            </button>
          </div>
        </div>
        {/* 2nd Row */}
        <div className="flex justify-start items-center gap-[16px]">
          <div className="w-[742px] h-[479px] flex flex-col justify-between items-start border-[1px] border-[#F2F2F2] rounded-[16px] p-[32px]">
            <div className="w-[678px] h-[214px] flex flex-col justify-start items-start space-y-[16px]">
              <div className="w-full h-[43px] flex flex-col justify-start items-start space-y-[4px]">
                <h1 className="font-albert font-bold text-[18px] leading-[21.6px] text-[#0F153E]">
                  Standard Burning Hours And Days
                </h1>
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">For new quotes</p>
              </div>
              <div className="w-full h-[51px] flex flex-col justify-start items-start space-y-[12px] rounded-[16px]">
                <div className="flex justify-start items-center gap-[12px]">
                  <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] w-[639px] h-[17px]">
                    Average Operating Hours Per Day
                  </p>
                  <p className="font-poppins font-semibold text-[14px] leading-[21px] text-[#CCCCCC] w-27px] h-[21px]">
                    ({value})
                  </p>
                </div>
                <div className="flex justify-center items-center w-full">
                  <div className="w-[678px]">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={value}
                      onChange={handleChange}
                      className="w-full h-2 bg-gray-200 rounded-full appearance-none cursor-pointer"
                    />

                    <div
                      className="absolute flex justify-center items-center"
                      style={{
                        left: `calc(${value}% - 10px)`,
                        top: '-10px',
                      }}
                    >
                      <div className="w-[18px] h-[18px] border-[1px] border-[#9BA1CF] rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-[51px] flex flex-col justify-start items-start space-y-[12px] rounded-[16px]">
                <div className="flex justify-start items-center gap-[12px]">
                  <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] w-[639px] h-[17px]">
                    Average Operating Hours Per Day
                  </p>
                  <p className="font-poppins font-semibold text-[14px] leading-[21px] text-[#CCCCCC] w-27px] h-[21px]">
                    (12)
                  </p>
                </div>
                <div className="flex justify-start items-center rounded-[12px] p-[4px] bg-[#F3F3F6] w-[678px] h-[55px]">
                  {days.map((day) => (
                    <div
                      className={`flex justify-center items-center w-[95.71px] h-[46px] p-[16px] gap-[20px] ${day.radius}`}
                      style={{ backgroundColor: `${day.bg}` }}
                    >
                      <p className={`font-albert font-normal text-[#0F153E] ${day.size}`}>{day.day}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <button className="w-[75px] h-[30px] font-albert font-bold text-[12px] leading-[14.2px] text-[#FFFFFF] rounded-[8px] py-[8px] px-[24px] flex justify-start items-start gap-[4px] bg-[#9BA1CF]">
              Save
            </button>
          </div>
          <div className="w-[363px] h-[474px] flex flex-col justify-between items-start space-y-[16px] border-[1px] border-[#F2F2F2] rounded-[16px] p-[32px]">
            <div className="w-[299px] h-[364px] flex flex-col justify-start items-start space-y-[16px]">
              <div className="w-[299px] h-[94px] flex flex-col justify-start items-start space-y-[4px]">
                <p className="font-albert font-bold text-[18px] leading-[21.6px] text-[#0F153E]">
                  Light As A Service Factor
                </p>
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                  Default value when switching from non-LaaS to LaaS. 'CO2' calculation factors are immediately included
                  when recalculating/adjusting a quotation.
                </p>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[299px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                  Standard LaaS Factor
                </p>
                <div className="w-[299px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[243px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[299px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">kWh-CO2 factor</p>
                <div className="w-[299px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[243px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[299px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Tree CO2 Factor</p>
                <div className="w-[299px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[243px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <button className="w-[75px] h-[30px] font-albert font-bold text-[12px] leading-[14.2px] text-[#FFFFFF] rounded-[8px] py-[8px] px-[24px] flex justify-start items-start gap-[4px] bg-[#9BA1CF]">
              Save
            </button>
          </div>
        </div>
        {/* 3rd Row */}
        <div className="flex justify-start items-center gap-[16px]">
          <div className="flex flex-col justify-start items-start space-y-[16px] w-[551px] h-[372px] border-[1px] border-[#F2F2F2] rounded-[16px] p-[32px]">
            <div className="w-full h-[43px] flex flex-col justify-start items-start space-y-[4px]">
              <h1 className="font-albert font-bold text-[18px] leading-[21.6px] text-[#0F153E]">
                Monthly Smart Connectivity Service Costs
              </h1>
              <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                Included in a recalculation/adjustment of a quotation.
              </p>
            </div>
            <div className="w-[487px] h-[74px] flex justify-start items-center gap-[24px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[207.5px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Minimum Quantity</p>
                <div className="w-[207.5px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[151.5px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[207.5px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                  Monthly Fee Per Product (€)
                </p>
                <div className="w-[207.5px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[151.5px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5 6.5H3.49992" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path
                  d="M18.8333 9L18.3734 15.8991C18.1964 18.554 18.1079 19.8815 17.2429 20.6907C16.3779 21.5 15.0475 21.5 12.3867 21.5H11.6133C8.95252 21.5 7.62212 21.5 6.75711 20.6907C5.8921 19.8815 5.8036 18.554 5.62661 15.8991L5.16667 9"
                  stroke="#B71B1C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path d="M9.5 11.5L10 16.5" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path d="M14.5 11.5L14 16.5" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path
                  d="M6.5 6.5C6.55588 6.5 6.58382 6.5 6.60915 6.49936C7.43259 6.47849 8.15902 5.95491 8.43922 5.18032C8.44784 5.15649 8.45667 5.12999 8.47434 5.07697L8.57143 4.78571C8.65431 4.53708 8.69575 4.41276 8.75071 4.3072C8.97001 3.88607 9.37574 3.59364 9.84461 3.51877C9.96213 3.5 10.0932 3.5 10.3553 3.5H13.6447C13.9068 3.5 14.0379 3.5 14.1554 3.51877C14.6243 3.59364 15.03 3.88607 15.2493 4.3072C15.3043 4.41276 15.3457 4.53708 15.4286 4.78571L15.5257 5.07697C15.5433 5.12992 15.5522 5.15651 15.5608 5.18032C15.841 5.95491 16.5674 6.47849 17.3909 6.49936C17.4162 6.5 17.4441 6.5 17.5 6.5"
                  stroke="#B71B1C"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <div className="w-[487px] h-[74px] flex justify-start items-center gap-[24px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[207.5px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Minimum Quantity</p>
                <div className="w-[207.5px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[151.5px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[207.5px] h-[74px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                  Monthly Fee Per Product (€)
                </p>
                <div className="w-[207.5px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[151.5px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5 6.5H3.49992" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path
                  d="M18.8333 9L18.3734 15.8991C18.1964 18.554 18.1079 19.8815 17.2429 20.6907C16.3779 21.5 15.0475 21.5 12.3867 21.5H11.6133C8.95252 21.5 7.62212 21.5 6.75711 20.6907C5.8921 19.8815 5.8036 18.554 5.62661 15.8991L5.16667 9"
                  stroke="#B71B1C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path d="M9.5 11.5L10 16.5" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path d="M14.5 11.5L14 16.5" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path
                  d="M6.5 6.5C6.55588 6.5 6.58382 6.5 6.60915 6.49936C7.43259 6.47849 8.15902 5.95491 8.43922 5.18032C8.44784 5.15649 8.45667 5.12999 8.47434 5.07697L8.57143 4.78571C8.65431 4.53708 8.69575 4.41276 8.75071 4.3072C8.97001 3.88607 9.37574 3.59364 9.84461 3.51877C9.96213 3.5 10.0932 3.5 10.3553 3.5H13.6447C13.9068 3.5 14.0379 3.5 14.1554 3.51877C14.6243 3.59364 15.03 3.88607 15.2493 4.3072C15.3043 4.41276 15.3457 4.53708 15.4286 4.78571L15.5257 5.07697C15.5433 5.12992 15.5522 5.15651 15.5608 5.18032C15.841 5.95491 16.5674 6.47849 17.3909 6.49936C17.4162 6.5 17.4441 6.5 17.5 6.5"
                  stroke="#B71B1C"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <div className="w-[487px] h-[30px] flex justify-between items-center">
              <button className="w-[75px] h-[30px] font-albert font-bold text-[12px] leading-[14.2px] text-[#FFFFFF] rounded-[8px] py-[8px] px-[24px] flex justify-start items-start gap-[4px] bg-[#9BA1CF]">
                Save
              </button>
              <div className="w-[78px] h-[18px] flex justify-start items-center gap-[4px]">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.75 9.5H14.25M9 14.75V4.25"
                    stroke="#4640FF"
                    stroke-width="1.05"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#4640FF]">Add More</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start space-y-[16px] w-[551px] h-[372px] border-[1px] border-[#F2F2F2] rounded-[16px] p-[32px] ">
            <div className="w-full h-[43px] flex flex-col justify-start items-start space-y-[4px]">
              <h1 className="font-albert font-bold text-[18px] leading-[21.6px] text-[#0F153E]">Energy Tax Scales</h1>
              <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                Included in a recalculation/adjustment of a quotation.
              </p>
            </div>
            <div className="w-[487px] h-[91px] flex justify-start items-center gap-[24px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[130.33px] h-[91px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                  Minimum consumption (kWh)
                </p>
                <div className="w-[130.33px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[74.33px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[130.33px] h-[60px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Fixed costs (€)</p>
                <div className="w-[130.33px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[74.33px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[130.33px] h-[60px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Price per kWh (€)</p>
                <div className="w-[130.33px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[74.33px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5 6.5H3.49992" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path
                  d="M18.8333 9L18.3734 15.8991C18.1964 18.554 18.1079 19.8815 17.2429 20.6907C16.3779 21.5 15.0475 21.5 12.3867 21.5H11.6133C8.95252 21.5 7.62212 21.5 6.75711 20.6907C5.8921 19.8815 5.8036 18.554 5.62661 15.8991L5.16667 9"
                  stroke="#B71B1C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path d="M9.5 11.5L10 16.5" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path d="M14.5 11.5L14 16.5" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path
                  d="M6.5 6.5C6.55588 6.5 6.58382 6.5 6.60915 6.49936C7.43259 6.47849 8.15902 5.95491 8.43922 5.18032C8.44784 5.15649 8.45667 5.12999 8.47434 5.07697L8.57143 4.78571C8.65431 4.53708 8.69575 4.41276 8.75071 4.3072C8.97001 3.88607 9.37574 3.59364 9.84461 3.51877C9.96213 3.5 10.0932 3.5 10.3553 3.5H13.6447C13.9068 3.5 14.0379 3.5 14.1554 3.51877C14.6243 3.59364 15.03 3.88607 15.2493 4.3072C15.3043 4.41276 15.3457 4.53708 15.4286 4.78571L15.5257 5.07697C15.5433 5.12992 15.5522 5.15651 15.5608 5.18032C15.841 5.95491 16.5674 6.47849 17.3909 6.49936C17.4162 6.5 17.4441 6.5 17.5 6.5"
                  stroke="#B71B1C"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <div className="w-[487px] h-[91px] flex justify-start items-center gap-[24px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[130.33px] h-[91px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                  Minimum consumption (kWh)
                </p>
                <div className="w-[130.33px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[74.33px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[130.33px] h-[60px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Fixed costs (€)</p>
                <div className="w-[130.33px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[74.33px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[130.33px] h-[60px]">
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Price per kWh (€)</p>
                <div className="w-[130.33px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    className="w-[74.33px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] outline-none"
                    placeholder="0"
                    value={basicRate}
                  />
                  <div className="w-[16px] h-[32px] flex flex-col justify-start items-center">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setBasicRate(basicRate + 1)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M3.38 10.2793L7.18333 6.47596C7.6325 6.0268 8.36749 6.0268 8.81666 6.47596L12.62 10.2793"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => setBasicRate(basicRate - 1)}
                    >
                      <path
                        d="M12.62 6.7207L8.81667 10.524C8.3675 10.9732 7.6325 10.9732 7.18334 10.524L3.38 6.7207"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5 6.5H3.49992" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path
                  d="M18.8333 9L18.3734 15.8991C18.1964 18.554 18.1079 19.8815 17.2429 20.6907C16.3779 21.5 15.0475 21.5 12.3867 21.5H11.6133C8.95252 21.5 7.62212 21.5 6.75711 20.6907C5.8921 19.8815 5.8036 18.554 5.62661 15.8991L5.16667 9"
                  stroke="#B71B1C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path d="M9.5 11.5L10 16.5" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path d="M14.5 11.5L14 16.5" stroke="#B71B1C" stroke-width="1.5" stroke-linecap="round" />
                <path
                  d="M6.5 6.5C6.55588 6.5 6.58382 6.5 6.60915 6.49936C7.43259 6.47849 8.15902 5.95491 8.43922 5.18032C8.44784 5.15649 8.45667 5.12999 8.47434 5.07697L8.57143 4.78571C8.65431 4.53708 8.69575 4.41276 8.75071 4.3072C8.97001 3.88607 9.37574 3.59364 9.84461 3.51877C9.96213 3.5 10.0932 3.5 10.3553 3.5H13.6447C13.9068 3.5 14.0379 3.5 14.1554 3.51877C14.6243 3.59364 15.03 3.88607 15.2493 4.3072C15.3043 4.41276 15.3457 4.53708 15.4286 4.78571L15.5257 5.07697C15.5433 5.12992 15.5522 5.15651 15.5608 5.18032C15.841 5.95491 16.5674 6.47849 17.3909 6.49936C17.4162 6.5 17.4441 6.5 17.5 6.5"
                  stroke="#B71B1C"
                  stroke-width="1.5"
                />
              </svg>
            </div>

            <div className="w-[487px] h-[30px] flex justify-between items-center">
              <button className="w-[75px] h-[30px] font-albert font-bold text-[12px] leading-[14.2px] text-[#FFFFFF] rounded-[8px] py-[8px] px-[24px] flex justify-start items-start gap-[4px] bg-[#9BA1CF]">
                Save
              </button>
              <div className="w-[78px] h-[18px] flex justify-start items-center gap-[4px]">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.75 9.5H14.25M9 14.75V4.25"
                    stroke="#4640FF"
                    stroke-width="1.05"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#4640FF]">Add More</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
