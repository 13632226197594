import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

const DeviceManagement = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const actionModalRef = useRef();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const salesStatus = ['Open', 'Sent', 'Lost', 'Won'];
  const [selectedRow, setSelectedRow] = useState(null);
  const [brokenInfo, setBrokenInfo] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);

  const tableHeadings = [
    'Type',
    'Device ID',
    'Project',
    'Status',
    'MAC Address',
    'Batch Number',
    'Outer Box',
    'Inner Box',
  ];
  const data = [
    {
      id: 1,
      type: 'Smart Fly - Zigbee',
      deviceId: '12348765984532',
      project: 'Firefly Project',
      status: 'Active',
      mac: '123-458-765984532',
      bactchNo: 'W202501082320951',
      outerBox: 'A2',
      innerBox: 'A-000001',
    },
    {
      id: 2,
      type: 'Smart Fly - Zigbee',
      deviceId: '12348765984532',
      project: 'Firefly Project',
      status: 'Broken',
      mac: '123-458-765984532',
      bactchNo: 'W202501082320951',
      outerBox: 'A2',
      innerBox: 'A-000001',
    },
  ];

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  //   Closing modal on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (actionModalRef.current && !actionModalRef.current.contains(event.target)) {
        setSelectedRow(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  return (
    <div className="flex flex-col justify-start items-center space-y-[30px]">
      <div className="flex justify-between items-center gap-[16px] w-[1247px] h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[363px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Device Management
        </h1>
        <div className="w-[258px] h-[48px] rounded-[8px] py-[8px] pr-[8px] pl-[16px] flex justify-start items-center gap-[8px] bg-[#F8F8F8]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="#434343"
              stroke-width="1.5"
            />
            <path d="M18.5 18.5L22 22" stroke="#434343" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          <input
            type="text"
            placeholder="Search here..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full bg-transparent outline-none font-poppins font-normal text-[12px] leading-[18px] text-[#434343]"
          />
        </div>
        <div className="w-[215px] h-[30px] flex justify-start items-center gap-[8px]">
          <span className="w-auto h-[30px] py-[8px] px-[16px] flex justify-center items-center gap-10px] rounded-[8px] bg-[#F8F8F8]">
            <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#000000]">Status</p>
          </span>
          <span className="w-auto h-[30px] py-[8px] px-[16px] flex justify-center items-center gap-10px] rounded-[8px] bg-[#F8F8F8]">
            <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#000000]">Project</p>
          </span>
          <span className="w-auto h-[30px] py-[8px] px-[16px] flex justify-center items-center gap-10px] rounded-[8px] bg-[#F8F8F8]">
            <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#000000]">Type</p>
          </span>
        </div>
        <div className="flex justify-start items-center cursor-pointer gap-[4px] w-[107px] h-[34px] border-[1px] border-[#9BA1CF] rounded-[8px] bg-[#fff] py-[8px] px-[24px]">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 9C3 12.3137 5.68629 15 9 15C12.3137 15 15 12.3137 15 9"
              stroke="#9BA1CF"
              stroke-width="1.125"
              stroke-linecap="round"
            />
            <path
              d="M9 3L9 10.5M9 10.5L11.25 8.25M9 10.5L6.75 8.25"
              stroke="#9BA1CF"
              stroke-width="1.125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#9BA1CF]">Import</p>
        </div>
        <div className="flex justify-start items-center cursor-pointer gap-[4px] w-[107px] h-[34px] rounded-[8px] bg-[#9BA1CF] py-[8px] px-[24px]">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 9C3 12.3137 5.68629 15 9 15C12.3137 15 15 12.3137 15 9"
              stroke="white"
              stroke-width="1.125"
              stroke-linecap="round"
            />
            <path
              d="M9 10.5L9 3M9 3L11.25 5.25M9 3L6.75 5.25"
              stroke="white"
              stroke-width="1.125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#fff]">Export</p>
        </div>
      </div>
      <div className="w-[1123px] h-[726px] flex flex-col justify-start items-start space-y-[16px] mr-[60px]">
        <div className="flex flex-col justify-start items-start  w-[1123px] h-[659px]">
          {/* Table Head */}
          <div className="flex justify-start items-start gap-[16px] w-full h-[50px] border-b-[1px] py-[16px] border-[#0000000D]">
            {tableHeadings.map((item, index) => (
              <label
                key={index}
                className="w-[140.83px] h-[18px] font-outfit font-normal text-[14px] leading-[17.64px] text-[#989EC2]"
              >
                {item}
              </label>
            ))}
          </div>
          {/* Table Rows */}
          {data
            .filter((item) => item.type.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((item, index) => (
              <div
                key={item.id}
                onClick={() => setSelectedRow(item.id)}
                className="relative flex justify-start items-start cursor-pointer gap-[16px] w-full h-[52px] border-b-[1px] py-[16px] border-[#0000000D]"
              >
                <label className="font-outfit font-bold text-[12px] w-[140.83px] h-[17px]  leading-[14.4px] text-[#0F153E]">
                  {item.type}
                </label>
                <label className="font-outfit font-normal text-[14px] w-[140.83px] h-[17px]  leading-[16.8px] text-[#0F153E]">
                  {item.deviceId}
                </label>
                <label className="font-outfit font-normal text-[14px] w-[140.83px] h-[17px]  leading-[16.8px] text-[#0F153E]">
                  {item.project}
                </label>
                <div className="relative w-[140.83px]">
                  {item.status === 'Active' ? (
                    <label
                      className={`font-outfit font-normal text-[10px] leading-[12px] w-[46px] h-[20px] rounded-[33px] text-[#55833B] bg-[#55833B1A] flex justify-center items-center py-[4px] px-[8px] gap-[8px]`}
                    >
                      {item.status}
                    </label>
                  ) : item.status === 'Broken' ? (
                    <span
                      onMouseEnter={() => {
                        setBrokenInfo(true);
                        setHoveredId(item.id);
                      }}
                      onMouseLeave={() => setBrokenInfo(false)}
                      className="flex justify-start items-center gap-[2px] w-[63px] h-[20px] py-[4px] px-[8px] bg-[#B71B1C1A] rounded-[33px]"
                    >
                      <p className="font-albert font-bold text-[10px] leading-[12px] text-[#B71B1C]">{item.status}</p>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.00008 10.5834C8.52091 10.5834 10.5834 8.52085 10.5834 6.00002C10.5834 3.47919 8.52091 1.41669 6.00008 1.41669C3.47925 1.41669 1.41675 3.47919 1.41675 6.00002C1.41675 8.52085 3.47925 10.5834 6.00008 10.5834Z"
                          stroke="#B71B1C"
                          stroke-width="0.75"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6 4.16669V6.45835"
                          stroke="#B71B1C"
                          stroke-width="0.75"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.99756 7.83331H6.00168"
                          stroke="#B71B1C"
                          stroke-width="0.75"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  ) : null}
                  {brokenInfo && item.id === hoveredId && (
                    <div className="flex flex-col justify-center items-center absolute top-[20px] right-10">
                      <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 0L20.4593 15H0.540708L10.5 0Z" fill="#B71B1C" fill-opacity="0.1" />
                      </svg>

                      <div className="flex justify-center items-center  w-[108px] h-[58px] bg-[#B71B1C1A] rounded-[12px] p-[12px]">
                        <label className="w-[84px] h-[34px] font-outfit font-normal text-[10px] leading-[20px] text-[#B71B1C]">
                          Replaced with ID 1234
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <label className="font-outfit font-normal text-[14px] w-[142.83px] h-[17px]  leading-[16.8px] text-[#0F153E]">
                  {item.mac}
                </label>

                <label className="font-outfit font-normal text-[14px] w-[140.83px] h-[17px]   leading-[16.8px] text-[#0F153E]">
                  {item.bactchNo}
                </label>
                <label className="font-outfit font-normal text-[14px] w-[140.83px] h-[17px]   leading-[16.8px] text-[#0F153E]">
                  {item.outerBox}
                </label>
                <label className="font-outfit font-normal text-[14px] w-[140.83px] h-[17px]   leading-[16.8px] text-[#0F153E]">
                  {item.innerBox}
                </label>

                {/* {selectedRow === item.id && (
                  <div
                    ref={actionModalRef}
                    className="absolute top-[56px] right-0 bg-[#FFFFFF] z-50 w-[308px] h-[76px] rounded-[12px] py-[20px] px-[16px] flex justify-start items-center gap-[16px] border-[1px] border-[#9BA1CF]"
                  >
                    <button className="w-[130px] h-[36px] font-poppins font-normal text-[14px] leading-[20px] text-[#F44336] rounded-[8px] border-[1px] border-[#00000014] bg-white py-[8px] px-[16px] gap-[8px] flex justify-center items-center">
                      Delete
                    </button>
                    <button className="w-[130px] h-[36px] font-poppins font-normal text-[14px] leading-[20px] text-[#000000] rounded-[8px] border-[1px] border-[#00000014] bg-white py-[8px] px-[16px] gap-[8px] flex justify-center items-center">
                      Duplicate
                    </button>
                  </div>
                )} */}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DeviceManagement;
