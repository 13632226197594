import React, { useState } from 'react';
// import ChangePasswordModal from '../components/modals/ChangePasswordModal';

const Profile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="flex flex-col justify-start items-center space-y-[30px]">
      <div className="flex justify-between items-center gap-[16px] w-[1247px] h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[684px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Profile
        </h1>
        <div className="flex justify-start items-center gap-[4px] w-[141px] h-[34px] rounded-[8px] bg-[#9BA1CF] py-[8px] px-[20px]">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.75 9H14.25M9 14.25V3.75"
              stroke="white"
              stroke-width="1.05"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#FFFFFF]">Add Product</p>
        </div>
      </div>
      <div className="w-[1074px] h-[96px] rounded-[64px] border-[1px] border-[#F2F2F2] p-[16px] flex justify-start items-center gap-[16px] mr-[110px]">
        <img src="" alt="user" className="w-[64px] h-[64px] rounded-[25.28px]" />
        <div className="w-[808px] h-[49px] flex flex-col justify-start items-start space-y-[8px]">
          <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E]">Waqas Atta</h1>
          <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">Admin</p>
        </div>
        <button className="w-[138px] h-[51px] flex justify-center items-center rounded-[32px] bg-[#F8F8F8] font-albert font-bold text-[14px] leading-[16.8px] text-[#434343]">
          Update Photo
        </button>
      </div>
      <div className="w-[1074px] h-[351px] rounded-[16px] border-[1px] border-[#F2F2F2] p-[32px] flex flex-col justify-start items-start space-y-[16px] mr-[110px]">
        <h1 className="font-albert font-semibold text-[18px] leading-[27px] text-[#434343]">Account Info</h1>
        <div className="w-[1010px] h-[180px] flex flex-col justify-start items-start space-y-[32px]">
          <div className="w-[1010px] h-[74px] flex justify-start items-start gap-[32px]">
            <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">First Name</label>
              <input
                type="text"
                className="w-[489px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Waqas"
              />
            </div>
            <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Last Name</label>
              <input
                type="text"
                className="w-[489px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Atta"
              />
            </div>
          </div>
          <div className="w-[1010px] h-[74px] flex justify-start items-start gap-[32px]">
            <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                Email Address
              </label>
              <input
                type="text"
                className="w-[489px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="waqas@gmail.com"
              />
            </div>
            <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                Phone Number
              </label>
              <input
                type="text"
                className="w-[489px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="123456789"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => setIsModalOpen(true)}
        className="w-[1010px] h-[48px] border-b-[1px] border-[#0000000D] cursor-pointer rounded-[10px] py-[15px] px-[8px] flex justify-start items-center gap-[8px] mr-[110px]"
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.6862 7.87272V6.08355C13.6862 3.98939 11.9878 2.29105 9.89366 2.29105C7.79949 2.28189 6.09449 3.97189 6.08533 6.06689V6.08355V7.87272"
            stroke="#434343"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.0693 17.7076H6.70184C4.95684 17.7076 3.54184 16.2935 3.54184 14.5476V10.9735C3.54184 9.22764 4.95684 7.81348 6.70184 7.81348H13.0693C14.8143 7.81348 16.2293 9.22764 16.2293 10.9735V14.5476C16.2293 16.2935 14.8143 17.7076 13.0693 17.7076Z"
            stroke="#434343"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M9.88575 11.835V13.6858" stroke="#434343" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <p className="w-[942px] h-[18px] font-albert font-normal text-[14px] leading-[17.64px] text-[#434343]">
          Change Password
        </p>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.00002 13.2797L10.3467 8.93306C10.86 8.41973 10.86 7.57973 10.3467 7.06639L6.00002 2.71973"
            stroke="#434343"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {/* {isModalOpen && (
          <ChangePasswordModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )} */}
      </div>
    </div>
  );
};

export default Profile;
