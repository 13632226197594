import React, { useRef, useState } from 'react';
// import { BASE_URL, PORT } from '../config';
import axios from 'axios';
import { useHistory } from 'react-router';

const AddNewProduct = () => {
  const ref = useRef();
  const history = useHistory();
  const basicRef = useRef(null);
  const descRef = useRef(null);
  const imagesRef = useRef(null);
  const pricingRef = useRef(null);
  const inventoryRef = useRef(null);
  const variationsRef = useRef(null);
  const customFieldsRef = useRef(null);
  const relatedProductsRef = useRef(null);
  const dimensionsRef = useRef(null);
  const shippingDetailsRef = useRef(null);
  const [highlightedId, setHighlightedId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [productType, setProductType] = useState('');
  const [description, setDescription] = useState('');
  const [weight, setWeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [depth, setDepth] = useState(0);
  const [height, setHeight] = useState(0);
  const [price, setPrice] = useState(0);
  const [costPrice, setCostPrice] = useState(0);
  const [salePrice, setSalePrice] = useState(0);

  const handleScrollToSection = (ref, id) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });

      setHighlightedId(id);
      setTimeout(() => {
        setHighlightedId(null);
      }, 1000);
    }
  };

  const sidebarMenu = [
    {
      id: 1,
      title: 'Basic Information',
    },
    {
      id: 2,
      title: 'Description',
    },
    {
      id: 3,
      title: 'Images & Videos',
    },
    {
      id: 4,
      title: 'Pricings',
    },
    {
      id: 5,
      title: 'Inventory',
    },
    {
      id: 6,
      title: 'Variations',
    },
    {
      id: 7,
      title: 'Custom Fields',
    },
    {
      id: 8,
      title: 'Related Products',
    },
    {
      id: 9,
      title: 'Dimensions & Weight',
    },
    {
      id: 10,
      title: 'Shipping Details',
    },
  ];

  const handleCreateProduct = async () => {
    setLoading(true);
    const payload = {
      name,
      createdAt: new Date().toISOString(),
      updatedAt: '2025-01-27T00:00:00Z',
      brandId: 123,
      type: productType,
      description,
      weight: parseInt(weight),
      width,
      depth,
      height,
      price: parseInt(price),
      costPrice: 75.0,
      salePrice: 80.0,
      taxClassId: 1,
      productTaxCode: 'TAX123',
      inventoryLevel: 50,
      isFreeShipping: true,
      costPrice,
      salePrice,
      isVisible: true,
      isFeatured: true,
      warranty: '1 year',
      binPickingNumber: 'BIN123',
      layoutFile: 'file.jpg',
      searchKeywords: ['sample', 'product', 'example'],
      availability: 'AVAILABLE',
      sortOrder: 1,
      condition: 'New',
      orderQuantityMinimum: 1,
      orderQuantityMaximum: 100,
      pageTitle: 'Sample Product Page',
      metaDescription: 'A detailed description of the sample product.',
      preorderReleaseDate: '2025-03-01T00:00:00Z',
      isPriceHidden: false,
      priceHiddenLabel: 'Hidden Price',
      openGraphTitle: 'Sample Product Open Graph Title',
      gtin: '123456789012',
      mpn: 'MPN123',
      calculatedPrice: 95.0,
      isSensor: false,
    };

    try {
      const response = await axios.post(`https://staging.check.firefly.online/products`, payload);

      console.log(response);
      setLoading(false);
      alert(`Product Created`);
      history.push('/products');
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };
  return (
    <div ref={basicRef} className="flex flex-col justify-start items-center space-y-[30px] pb-8 bg-white">
      <div className="flex justify-between items-center gap-[16px] bg-white w-full h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[938px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Add Products
        </h1>

        <div className="flex justify-center items-center gap-[4px] w-[87px] h-[30px] rounded-[8px] border-[1px] border-[#9BA1CF] py-[8px] px-[24px]">
          <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#989EC2]">Cancel</p>
        </div>
        <div
          onClick={() => handleCreateProduct()}
          className="flex justify-center items-center cursor-pointer gap-[4px] w-[75px] h-[30px] rounded-[8px] bg-[#9BA1CF] py-[8px] px-[24px]"
        >
          {loading ? (
            <div className="loader w-[20px] h-[20px] border-[2px] border-t-[2px] border-t-transparent border-[#FFFFFF] rounded-full animate-spin"></div>
          ) : (
            <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#FFFFFF]">Save</p>
          )}
        </div>
      </div>
      <div className="flex justify-start items-start gap-8 w-full h-auto pl-[30px] bg-white">
        <div className="sticky top-4 flex-col justify-start items-start w-[225px] h-[698px] bg-[#F3F3F6] rounded-[8px] p-[32px] space-y-[16px]">
          {sidebarMenu.map((item) => {
            let ref;
            switch (item.id) {
              case 1:
                ref = basicRef;
                break;
              case 2:
                ref = descRef;
                break;
              case 3:
                ref = imagesRef;
                break;
              case 4:
                ref = pricingRef;
                break;
              case 5:
                ref = inventoryRef;
                break;
              case 6:
                ref = variationsRef;
                break;
              case 7:
                ref = customFieldsRef;
                break;
              case 8:
                ref = relatedProductsRef;
                break;
              case 9:
                ref = dimensionsRef;
                break;
              case 10:
                ref = shippingDetailsRef;
                break;
              default:
                ref = null;
            }
            return (
              <div
                key={item.id}
                onClick={() => handleScrollToSection(ref, item.id)}
                className="w-[161px] h-[49px] cursor-pointer border-b-[1px] border-[#FFFFFF] py-[16px] px-[8px] flex justify-start items-center"
              >
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">{item.title}</p>
              </div>
            );
          })}
        </div>
        <div className="w-[870px] h-[3973px] bg-[#FFFFFF] rounded-[8px] flex flex-col justify-start items-start space-y-[24px]">
          {/* basic info*/}
          <div
            className={`w-[870px] h-[643px] rounded-[8px] border-[1px] border-[#F2F2F2] shadow-[0_0_10px_0_#00000005] p-[24px] flex flex-col justify-start items-start space-y-[32px] ${
              highlightedId === 1 ? 'bg-[#F3F3F6]' : ' bg-white'
            }`}
          >
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E] w-full h-[24px]">
              Basic Info
            </h1>
            <div className="w-full h-[17px] flex justify-start items-center gap-[8px]">
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="14" height="14" rx="7" fill="#4640FF" />
                <path
                  d="M9.66671 5.5L6.00004 9.16667L4.33337 7.5"
                  stroke="white"
                  stroke-width="0.666667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-albert font-bold text-[14px] leading-[16.8px] text-[#0F153E]">Active</p>
            </div>
            <div className="w-[822px] h-[490px] flex flex-col justify-start items-start space-y-[24px]">
              <div className="w-full h-[74px] flex justify-start items-center gap-[16px]">
                <div className="w-[403px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
                  <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                    Product Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-[403px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white"
                  />
                </div>
                <div className="w-[403px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
                  <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">SKU</label>
                  <input
                    type="text"
                    className="w-[403px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white"
                  />
                </div>
              </div>
              <div className="w-full h-[98px] flex justify-start items-center gap-[16px]">
                <div className="w-[403px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
                  <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                    Product Type
                  </label>
                  <input
                    type="text"
                    placeholder="Physical"
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                    className="w-[403px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white"
                  />
                </div>
                <div className="w-[403px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
                  <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                    Default Price ( excluding tax )
                  </label>
                  <input
                    type="text"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="w-[403px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white"
                  />
                  <div className="w-full h-[16px] flex justify-end items-center gap-[7px]">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.26833 13.28L9.615 8.9333C10.1283 8.41997 10.1283 7.57997 9.615 7.06664L5.26833 2.71997"
                        stroke="#989EC2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#989EC2]">More pricing</p>
                  </div>
                </div>
              </div>
              <div className="w-full h-[74px] flex justify-start items-center gap-[16px]">
                <div className="w-[403px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
                  <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Brand</label>
                  <input
                    type="text"
                    className="w-[403px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white"
                  />
                </div>
                <div className="w-[403px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
                  <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Weight</label>
                  <div className="flex justify-start items-center w-[403px] h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white">
                    <input
                      type="text"
                      className="outline-none w-[90%]"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                    <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">KGS</p>
                  </div>
                </div>
              </div>
              <div className="w-[822px] h-[172px] flex flex-col justify-start items-start space-y-[8px]">
                <div className="w-full h-[18px] flex justify-start items-center gap-[8px]">
                  <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E] w-[704px] h-[17px]">
                    Categories
                  </label>
                  <div className="w-[120px] h-[18px] flex justify-start items-center gap-[4px]">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.75 9H14.25M9 14.25V3.75"
                        stroke="#4640FF"
                        stroke-width="1.05"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#4640FF]">Add Categories</p>
                  </div>
                </div>
                <input
                  type="text"
                  className="w-[822px] h-[146px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white"
                />
              </div>
            </div>
          </div>
          {/* decription */}
          <div
            ref={descRef}
            className={`w-[870px] h-[250px] border-[1px] border-[#F2F2F2] rounded-[8px] p-[24px] flex flex-col justify-start items-start space-y-[32px] ${
              highlightedId === 2 ? 'bg-[#F3F3F6]' : ' bg-white'
            }`}
          >
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E] w-full h-[24px]">
              Description
            </h1>
            <input
              type="text"
              name=""
              id=""
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-[822px] h-[146px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
            />
          </div>
          {/* images & videos */}
          <div
            ref={imagesRef}
            className={`w-[870px] h-[351px] border-[1px] border-[#F2F2F2] rounded-[8px] p-[24px] flex flex-col justify-start items-start space-y-[32px] ${
              highlightedId === 3 ? 'bg-[#F3F3F6]' : 'bg-white'
            }`}
          >
            <div className="w-[822px] h-[51px] border-b-[1px] border-[#FFFFFF] flex justify-start items-center gap-[10px]">
              <div className="w-[496px] h-[51px] flex flex-col justify-start items-start space-y-[10px]">
                <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E] w-full h-[24px]">
                  Images & Videos
                </h1>
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] w-full h-[17px]">
                  Add images and videos of your product to engage customers.
                </p>
              </div>
              <div className="flex justify-start items-start gap-4">
                <div className="w-[153px] h-[34px] rounded-[8px] border-[1px] border-[#4640FF] py-[8px] px-[20px] flex justify-start items-center gap-[4px]">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.75 9H14.25M9 14.25V3.75"
                      stroke="#4640FF"
                      stroke-width="1.05"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <label className="font-albert font-bold text-[12px] leading-[14.4px] text-[#4640FF]">
                    Add From URL
                  </label>
                </div>
                <div className="w-[153px] h-[34px] rounded-[8px] border-[1px] border-[#4640FF] py-[8px] px-[20px] flex justify-start items-center gap-[4px]">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.71429 14.25C2.93908 14.25 1.5 12.8278 1.5 11.0735C1.5 9.31921 2.93908 7.89706 4.71429 7.89706C4.92738 7.89706 5.13562 7.91755 5.33712 7.95665M10.7857 6.02041C11.2325 5.86434 11.7134 5.77941 12.2143 5.77941C12.7053 5.77941 13.177 5.86101 13.6163 6.01123M5.33712 7.95665C5.1653 7.49835 5.07143 7.00265 5.07143 6.48529C5.07143 4.14621 6.99021 2.25 9.35714 2.25C11.5619 2.25 13.3778 3.89528 13.6163 6.01123M5.33712 7.95665C5.76044 8.03879 6.15396 8.20305 6.5 8.43196M13.6163 6.01123C15.2947 6.58518 16.5 8.16084 16.5 10.0147C16.5 12.045 15.0545 13.7416 13.125 14.1542"
                      stroke="#4640FF"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <path
                      d="M9 12V16.5M9 12L10.5 13.5M9 12L7.5 13.5"
                      stroke="#4640FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <label className="font-albert font-bold text-[12px] leading-[14.4px] text-[#4640FF]">
                    Upload Images
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center w-[822px] h-[146px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]">
              <div className="w-[350px] h-[86px] flex flex-col justify-center items-center space-y-[10px]">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 13.3333C13.4728 13.3333 14.6667 12.1394 14.6667 10.6667C14.6667 9.19391 13.4728 8 12 8C10.5273 8 9.33337 9.19391 9.33337 10.6667C9.33337 12.1394 10.5273 13.3333 12 13.3333Z"
                    stroke="#989EC2"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.3333 2.66675H12C5.33329 2.66675 2.66663 5.33341 2.66663 12.0001V20.0001C2.66663 26.6667 5.33329 29.3334 12 29.3334H20C26.6666 29.3334 29.3333 26.6667 29.3333 20.0001V13.3334"
                    stroke="#989EC2"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24 2.66675V10.6667L26.6667 8.00008"
                    stroke="#989EC2"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24 10.6667L21.3334 8"
                    stroke="#989EC2"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.56006 25.2668L10.1334 20.8535C11.1867 20.1468 12.7067 20.2268 13.6534 21.0402L14.0934 21.4268C15.1334 22.3202 16.8134 22.3202 17.8534 21.4268L23.4001 16.6668C24.4401 15.7735 26.1201 15.7735 27.1601 16.6668L29.3334 18.5335"
                    stroke="#989EC2"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">
                  Drag & Drop images here to upload.
                </p>
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                  bmp, jpeg, png, wbmp, xbm or webp. Maximum 8 MB.
                </p>
              </div>
            </div>
            <div className="w-[822px] h-[42px] flex flex-col justify-start items-start space-y-[8px]">
              <p className="font-albert font-bold text-[14px] leading-[16.8px] text-[#0F153E]">Upload Images</p>
              <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                No videos have been added yet
              </p>
            </div>
          </div>
          {/* Variations */}
          <div
            ref={variationsRef}
            className={`w-[870px] h-[392px] rounded-[8px] border-[1px] border-[#F2F2F2] p-[24px] flex flex-col justify-start items-start space-y-[32px] ${
              highlightedId === 6 ? 'bg-[#F3F3F6]' : 'bg-white'
            }`}
          >
            <div className="w-[822px] h-[42px] flex flex-col justify-start items-start space-y-[8px]">
              <p className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E]">Variations</p>
              <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                Add variant options like size and color to create variants for this product.
              </p>
            </div>
            <div className="w-[822px] h-[255px] flex flex-col justify-start items-start space-y-[16px]">
              <div className="w-full h-[24px] flex justify-between items-center border-b-[1px] border-[#FFFFFF] ">
                <p className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E] w-[719px] h-[24px]">
                  Variants
                </p>
                <div className="w-[97px] h-[18px] rounded-[8px] flex justify-start items-center gap-[4px]">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.5284 6.33874L9.63844 11.2287C9.06094 11.8062 8.11594 11.8062 7.53844 11.2287L2.64844 6.33874"
                      stroke="#4640FF"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#4640FF]">Add Product</p>
                </div>
              </div>
              <div className="w-full h-[215px] border-[1px] border-[#E5E5E5] rounded-[4px] flex flex-col justify-start items-start">
                {/* Headings */}
                <div className="w-full h-[51px] flex justify-start items-center">
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">Image</label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">
                      Variants
                    </label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">SKUs</label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">
                      Manufacturer SKUs
                    </label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">Prices</label>
                  </span>
                </div>
                {/* Rows */}
                <div className="w-full h-[51px] flex justify-start items-center">
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.69999 8.83333C8.62047 8.83333 9.36666 8.08714 9.36666 7.16667C9.36666 6.24619 8.62047 5.5 7.69999 5.5C6.77952 5.5 6.03333 6.24619 6.03333 7.16667C6.03333 8.08714 6.77952 8.83333 7.69999 8.83333Z"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.0332 2.1665H7.69991C3.53324 2.1665 1.86658 3.83317 1.86658 7.99984V12.9998C1.86658 17.1665 3.53324 18.8332 7.69991 18.8332H12.6999C16.8666 18.8332 18.5332 17.1665 18.5332 12.9998V8.83317"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.2 2.1665V7.1665L16.8666 5.49984"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.2 7.16667L13.5333 5.5"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.42493 16.2918L6.53326 13.5335C7.19159 13.0918 8.14159 13.1418 8.73326 13.6501L9.00826 13.8918C9.65826 14.4501 10.7083 14.4501 11.3583 13.8918L14.8249 10.9168C15.4749 10.3585 16.5249 10.3585 17.1749 10.9168L18.5333 12.0835"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">300K</label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">
                      LPPU-6060-25-3
                    </label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">
                      LPPU-6060-25-3
                    </label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">€499</label>
                  </span>
                </div>
                <div className="w-full h-[51px] flex justify-start items-center">
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.69999 8.83333C8.62047 8.83333 9.36666 8.08714 9.36666 7.16667C9.36666 6.24619 8.62047 5.5 7.69999 5.5C6.77952 5.5 6.03333 6.24619 6.03333 7.16667C6.03333 8.08714 6.77952 8.83333 7.69999 8.83333Z"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.0332 2.1665H7.69991C3.53324 2.1665 1.86658 3.83317 1.86658 7.99984V12.9998C1.86658 17.1665 3.53324 18.8332 7.69991 18.8332H12.6999C16.8666 18.8332 18.5332 17.1665 18.5332 12.9998V8.83317"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.2 2.1665V7.1665L16.8666 5.49984"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.2 7.16667L13.5333 5.5"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.42493 16.2918L6.53326 13.5335C7.19159 13.0918 8.14159 13.1418 8.73326 13.6501L9.00826 13.8918C9.65826 14.4501 10.7083 14.4501 11.3583 13.8918L14.8249 10.9168C15.4749 10.3585 16.5249 10.3585 17.1749 10.9168L18.5333 12.0835"
                        stroke="#989EC2"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">300K</label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">
                      LPPU-6060-25-3
                    </label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">
                      LPPU-6060-25-3
                    </label>
                  </span>
                  <span className="w-[164.4px] h-full flex justify-center items-center border-[1px] border-[#F2F2F2] py-[4px]">
                    <label className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2]">€499</label>
                  </span>
                </div>
                <div className="w-full h-[51px] flex justify-start items-center gap-[10px] px-4 pt-4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5 12.5H19M12 19.5V5.5"
                      stroke="#4640FF"
                      stroke-width="1.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p className="font-albert font-bold text-[14px] leading-[16.8px] text-[#4640FF]">Add Variants</p>
                </div>
              </div>
            </div>
          </div>
          {/* Pricings */}
          <div
            ref={pricingRef}
            className={`flex flex-col justify-start items-start rounded-[8px] space-y-[32px] p-[24px] border-[1px] border-[#F2F2F2] w-[870px] h-[531px] ${
              highlightedId === 4 ? 'bg-[#F3F3F6]' : 'bg-white'
            }`}
          >
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E]">Pricings</h1>
            <div className="w-[822px] h-[165px] flex flex-col justify-start items-start space-y-[16px]">
              <div className="flex flex-col justify-start items-start space-y-[8px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">
                  Default Price ( excluding tax )
                </label>
                <div className="w-[822px] h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input type="text" placeholder="€ 0" className="outline-none w-[790px] h-[17px]" />
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">
                  Tax Class
                </label>
                <div className="w-[822px] h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input type="text" placeholder="€ 0" className="outline-none w-[764px] h-[17px]" />
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.5284 6.3385L9.63844 11.2285C9.06094 11.806 8.11594 11.806 7.53844 11.2285L2.64844 6.3385"
                      stroke="#434343"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-[178px] h-[18px] flex justify-start items-center gap-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#4640FF]">
                Hide Advanced Pricing
              </label>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.5284 6.3385L9.63844 11.2285C9.06094 11.806 8.11594 11.806 7.53844 11.2285L2.64844 6.3385"
                  stroke="#434343"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="w-[822px] h-[74px] flex justify-start items-center gap-[16px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[403px] h-[74px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">Cost</label>
                <div className="w-full h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    placeholder="€ 0"
                    value={costPrice}
                    onChange={(e) => setCostPrice(e.target.value)}
                    className="outline-none w-full h-[17px]"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[403px] h-[74px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">MRSP</label>
                <div className="w-full h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input type="text" placeholder="€ 0" className="outline-none w-full h-[17px]" />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">Sale Price</label>
              <div className="w-[822px] h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                <input
                  type="text"
                  placeholder="€ 0"
                  value={salePrice}
                  onChange={(e) => setSalePrice(e.target.value)}
                  className="outline-none w-[790px] h-[17px]"
                />
              </div>
            </div>
          </div>
          {/* Inventory */}
          <div
            ref={inventoryRef}
            className={`flex flex-col justify-start items-start rounded-[8px] space-y-[32px] p-[24px] border-[1px] border-[#F2F2F2] w-[870px] h-[121px] ${
              highlightedId === 5 ? 'bg-[#F3F3F6]' : 'bg-white'
            }`}
          >
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E]">Inventory</h1>
            <div className="w-[822px] h-[17px] flex justify-start items-center gap-[8px]">
              <input type="radio" />
              <label className="font-albert font-bold text-[14px] leading-[16.9px] text-[#0F153E]">
                Track Inventory
              </label>
            </div>
          </div>
          {/* custom fields */}
          <div
            ref={customFieldsRef}
            className={`flex flex-col justify-start items-start rounded-[8px] space-y-[32px] p-[24px] border-[1px] border-[#F2F2F2] w-[870px] h-[311px] ${
              highlightedId === 7 ? 'bg-[#F3F3F6]' : 'bg-white'
            }`}
          >
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E]">Custom Fields</h1>
            <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
              Custom fields allow you to specify additional information that will appear on the products page. Custom
              fields appear automatically in the product's details if they are defined on the product. If you don't want
              to show any custom fields, simply remove them from the product.
            </p>
            <div className="w-[822px] h-[74px] flex justify-start items-center gap-[16px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[403px] h-[74px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">
                  Custom Field Name
                </label>
                <div className="w-full h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[12px] flex justify-start items-center gap-[8px]">
                  <input type="text" placeholder="eg wine wintage" className="outline-none w-full h-full" />
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[403px] h-[74px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">
                  Custom Field Value
                </label>
                <div className="w-full h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input type="text" placeholder="1998" className="outline-none w-full h-full" />
                </div>
              </div>
            </div>
            <div className="flex justify-start items-center gap-[4px]">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.75 9H14.25M9 14.25V3.75"
                  stroke="#4640FF"
                  stroke-width="1.05"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <label className="font-albert font-bold text-[12px] leading-[14.4px] text-[#4640FF]">
                Add Custom Fields
              </label>
            </div>
          </div>
          {/* related products */}
          <div
            ref={relatedProductsRef}
            className={`w-[870px] h-[646px] rounded-[8px] p-[24px] flex flex-col justify-start items-start space-y-[32px] border-[1px] border-[#F2F2F2] shadow-[0px_4px_6px_#00000005] ${
              highlightedId === 8 ? 'bg-[#F3F3F6]' : 'bg-white'
            }`}
          >
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E]">Related Products</h1>
            <div className="w-[822px] h-[172px] flex flex-col justify-start items-start space-y-[8px]">
              <div className="w-full h-[18px] flex justify-start items-center gap-[8px]">
                <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E] w-[704px] h-[17px]">
                  Select a Category
                </label>
              </div>
              <input
                type="text"
                className="w-[822px] h-[146px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white"
              />
            </div>
            <div className="w-[822px] h-[172px] flex flex-col justify-start items-start space-y-[8px]">
              <div className="w-full h-[18px] flex justify-start items-center gap-[8px]">
                <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E] w-[704px] h-[17px]">
                  Related Producst (0)
                </label>
              </div>
              <input
                type="text"
                className="w-[822px] h-[146px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white"
              />
            </div>
            <div className="w-[822px] h-[134px] flex flex-col justify-start items-start space-y-[16px]">
              <div className="w-full h-[18px] flex justify-start items-center gap-[8px]">
                <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E] w-[704px] h-[17px]">
                  Related Producst (0)
                </label>
              </div>
              <div className="flex justify-center items-center w-[822px] h-[101px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px] bg-white">
                <div className="w-[513px] h-[44px] flex flex-col justify-center items-center space-y-[10px] ">
                  <p className="font-albert font-bold text-[14px] leading-[16.8px] text-[#989EC2] ">
                    No related products choosen yet
                  </p>
                  <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] ">
                    Select a category above to surface products you can select as related products.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* dimensions and weight */}
          <div
            ref={dimensionsRef}
            className={`w-[870px] h-[334px] rounded-[8px] p-[24px] flex flex-col justify-start items-start space-y-[32px] border-[1px] border-[#F2F2F2] shadow-[0px_4px_6px_#00000005] ${
              highlightedId === 9 ? 'bg-[#F3F3F6]' : 'bg-white'
            }`}
          >
            <div className="w-[822px] h-[74px] flex flex-col justify-start items-start space-y-[16px] ">
              <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E]">Dimensions & Weight</h1>
              <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                Enter the dimensions and weight of this product to help calculate shipping rate. These measurements are
                for the product's shipping container. They are used to help calculate shipping price and do not show up
                on your storefront.
              </p>
            </div>
            <div className="w-[822px] h-[74px] flex justify-start items-center gap-[16px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[403px] h-[74px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">
                  Weight (KGS)
                </label>
                <div className="w-full h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[12px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    placeholder="eg wine wintage"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    className="outline-none w-full h-full"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[403px] h-[74px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">
                  Width (Centimeters)
                </label>
                <div className="w-full h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    placeholder="1998"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                    className="outline-none w-full h-full"
                  />
                </div>
              </div>
            </div>
            <div className="w-[822px] h-[74px] flex justify-start items-center gap-[16px]">
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[403px] h-[74px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">
                  Height (Centimeters)
                </label>
                <div className="w-full h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[12px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    placeholder="eg wine wintage"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    className="outline-none w-full h-full"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-[403px] h-[74px]">
                <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">
                  Depth (Centimeters)
                </label>
                <div className="w-full h-[49px] bg-white rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                  <input
                    type="text"
                    placeholder="1998"
                    value={depth}
                    onChange={(e) => setDepth(e.target.value)}
                    className="outline-none w-full h-full"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* shipping details */}
          <div
            ref={shippingDetailsRef}
            className={`w-[870px] h-[334px] rounded-[8px] p-[24px] flex flex-col justify-start items-start space-y-[32px] border-[1px] border-[#F2F2F2] shadow-[0px_4px_6px_#00000005] ${
              highlightedId === 10 ? 'bg-[#F3F3F6]' : 'bg-white'
            }`}
          >
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E]">Shipping Details</h1>
            <div className="flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.88px] text-[#0F153E]">
                Fixed Shipping Price
              </label>
              <div className="w-[822px] h-[49px] rounded-[4px] bg-white border-[1px] border-[#E5E5E5] p-[16px] flex justify-start items-center gap-[8px]">
                <input type="text" placeholder="€ 0" className="outline-none w-[790px] h-[17px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewProduct;
