import React from 'react';

const AddNewClient = () => {
  return (
    <div className="flex flex-col justify-start items-center space-y-[30px]">
      <div className="flex justify-between items-center gap-[16px] w-[1247px] h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[684px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Clients
        </h1>

        <div
          //   onClick={() => navigate("/add-new-client")}
          className="flex justify-start items-center cursor-pointer gap-[4px] w-[141px] h-[34px] rounded-[8px] bg-[#9BA1CF] py-[8px] px-[15px]"
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.75 9H14.25M9 14.25V3.75"
              stroke="white"
              stroke-width="1.05"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#FFFFFF]">Add Client</p>
        </div>
      </div>
      <div className="w-[1123px] h-[722px] border-[1px] border-[#F2F2F2] bg-[#FFFFFF] p-[32px] flex flex-col justify-start items-start space-y-[16px] mr-[60px]">
        <div className="w-[1059px] h-[527px] bg-[#FFFFFF] flex justify-start items-center gap-[16px]">
          <div className="w-[342.33px] h-[526px] rounded-[8px] flex flex-col justify-start items-start space-y-[20px] p-[16px] border-[1px] border-[#F2F2F2]">
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#121212]">Contact Person</h1>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Role</label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Szűts"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">First Name</label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Szűts"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Last Name</label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Szűts"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                Email Address
              </label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Waqas@example.com"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                Phone Number
              </label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="123456789"
              />
            </div>
          </div>
          <div className="w-[342.33px] h-[526px] rounded-[8px] flex flex-col justify-start items-start space-y-[20px] p-[16px] border-[1px] border-[#F2F2F2]">
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#121212]">Organization</h1>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                Chamber of Commerce
              </label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Szűts"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                Company Name
              </label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Szűts"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                Company's Registration Number
              </label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Szűts"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                Company's Chamber
              </label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="123456789"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                Debter Number
              </label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="123456789"
              />
            </div>
          </div>
          <div className="w-[342.33px] h-[526px] rounded-[8px] flex flex-col justify-start items-start space-y-[20px] p-[16px] border-[1px] border-[#F2F2F2]">
            <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#121212]">Invoice Address</h1>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Address</label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Szűts"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Postal Code</label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Szűts"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">City</label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="Szűts"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Province</label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="123456789"
              />
            </div>
            <div className="w-[310.33px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
              <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Country</label>
              <input
                type="text"
                className="w-[310.33px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
                placeholder="123456789"
              />
            </div>
          </div>
        </div>
        <div className="w-[1059px] h-[115px] flex flex-col justify-start items-start space-y-[8px]">
          <div className="w-[310.33px] h-[115px] flex flex-col justify-start items-start space-y-[8px]">
            <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Notes</label>
            <input
              type="text"
              className="w-[1059px] h-[90px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
              placeholder="Write here"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewClient;
