import Sidebar from 'components/Sidebar/Sidebar';
import React from 'react';

const DashboardLayout = ({ children }) => (
  <div className="flex fixed w-full h-full">
    <Sidebar />
    <div className="flex-1 overflow-y-auto">{children}</div>
  </div>
);

export default DashboardLayout;