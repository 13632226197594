import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

const Offers = () => {
  const actionModalRef = useRef();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const salesStatus = ['Open', 'Sent', 'Lost', 'Won'];
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const tableHeadings = ['Reference', 'Customer', 'Amount', 'Created by', '', 'Last Updated', ''];
  const data = [
    {
      id: 1,
      status: 'Open',
      reference: 'ARNYC-01888',
      customer: 'Waqas Atta',
      amount: '£400,000',
      createdBy: 'Waqas Atta',
      lastUpdateOn: 'October 31, 2017',
    },
    {
      id: 2,
      status: 'Won',
      reference: 'ARNYC-01888',
      customer: 'Waqas Atta',
      amount: '£400,000',
      createdBy: 'Waqas Atta',
      lastUpdateOn: 'October 31, 2017',
    },
    {
      id: 3,
      status: 'Won',
      reference: 'ARNYC-01888',
      customer: 'Waqas Atta',
      amount: '£400,000',
      createdBy: 'Waqas Atta',
      lastUpdateOn: 'October 31, 2017',
    },
    {
      id: 4,
      status: 'Won',
      reference: 'ARNYC-01888',
      customer: 'Waqas Atta',
      amount: '£400,000',
      createdBy: 'Waqas Atta',
      lastUpdateOn: 'October 31, 2017',
    },
  ];

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  //   Closing modal on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (actionModalRef.current && !actionModalRef.current.contains(event.target)) {
        setSelectedRow(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  return (
    <div className="flex flex-col justify-start items-center space-y-[30px]">
      <div className="flex justify-between items-center gap-[16px] w-[1247px] h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[684px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Offers
        </h1>
        <div className="w-[258px] h-[30px] flex justify-start items-center gap-[8px]">
          {salesStatus.map((item, index) => (
            <span
              key={index}
              className="w-auto h-[30px] py-[8px] px-[16px] flex justify-center items-center gap-10px] rounded-[8px] bg-[#F8F8F8]"
            >
              <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#000000]">{item}</p>
            </span>
          ))}
        </div>
        <div
          onClick={() => history.push('/add-new-offer')}
          className="flex justify-start items-center cursor-pointer gap-[4px] w-[141px] h-[34px] rounded-[8px] bg-[#9BA1CF] py-[8px] px-[15px]"
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.75 9H14.25M9 14.25V3.75"
              stroke="white"
              stroke-width="1.05"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#FFFFFF]">Add New Offer</p>
        </div>
      </div>
      <div className="w-[1123px] h-[723px] flex flex-col justify-start items-start space-y-[16px] mr-[60px]">
        <div className="w-[1123px] h-[48px] rounded-[8px] py-[8px] pr-[8px] pl-[16px] flex justify-start items-center gap-[8px] bg-[#F8F8F8]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="#434343"
              stroke-width="1.5"
            />
            <path d="M18.5 18.5L22 22" stroke="#434343" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          <input
            type="text"
            placeholder="Search here"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full bg-transparent outline-none font-poppins font-normal text-[12px] leading-[18px] text-[#434343]"
          />
        </div>
        <div className="flex flex-col justify-start items-start  w-[1123px] h-[659px]">
          {/* Table Head */}
          <div className="flex justify-start items-start gap-[44px] w-full h-[56px] border-b-[1px] py-[16px] border-[#0000000D]">
            <span className="w-[117.2px] h-[20px] flex justify-start items-center gap-[16px]">
              <label className="flex items-center space-x-2 cursor-pointer">
                <input type="checkbox" className="hidden" checked={isChecked} onChange={handleCheckboxChange} />
                <div
                  className={`w-[20px] h-[20px] rounded-[6px] border-[2px] border-[#D0D5DD] flex items-center justify-center transition-all duration-200
          ${isChecked ? 'bg-white border-[#9BA1CF]' : 'bg-white'}`}
                >
                  {isChecked && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 text-[#9BA1CF]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </div>
              </label>
              <label className="font-outfit font-normal text-[14px] leading-[17.64px] text-[#989EC2]">Status</label>
            </span>
            {tableHeadings.map((item, index) => (
              <label className="w-[117.2px] h-[20px] font-outfit font-normal text-[14px] leading-[17.64px] text-[#989EC2]">
                {item}
              </label>
            ))}
          </div>
          {/* Table Rows */}
          {data
            .filter((item) => item.customer.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((item, index) => (
              <div
                key={item.id}
                onClick={() => setSelectedRow(item.id)}
                className="relative flex justify-start items-start cursor-pointer gap-[44px] w-full h-[56px] border-b-[1px] py-[16px] border-[#0000000D]"
              >
                <span className="w-[101.88px] h-[20px] flex justify-start items-center gap-[16px]">
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <input type="checkbox" className="hidden" checked={isChecked} onChange={handleCheckboxChange} />
                    <div
                      className={`w-[20px] h-[20px] rounded-[6px] border-[2px] border-[#D0D5DD] flex items-center justify-center transition-all duration-200
          ${isChecked ? 'bg-white border-[#9BA1CF]' : 'bg-white'}`}
                    >
                      {isChecked && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 text-[#9BA1CF]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      )}
                    </div>
                  </label>
                  <label className="w-[64px] h-[30px] rounded-[8px] py-[8px] px-[16px] gap-[10px] bg-[#F8F8F8] flex justify-center items-center font-outfit font-normal text-[12px] leading-[14.4px] text-[#000000]">
                    {item.status}
                  </label>
                </span>
                <label className="font-outfit font-normal text-[14px] w-[101.88px] h-[20px]  leading-[16.8px] text-[#0F153E]">
                  {item.reference}
                </label>
                <label className="font-outfit font-normal text-[14px] w-[101.88px] h-[20px]  leading-[16.8px] text-[#0F153E]">
                  {item.customer}
                </label>
                <label className="font-outfit font-normal text-[14px] w-[101.88px] h-[20px]  leading-[16.8px] text-[#0F153E]">
                  {item.amount}
                </label>
                <label className="font-outfit font-normal text-[14px] w-[101.88px] h-[20px]  leading-[16.8px] text-[#0F153E]">
                  {item.createdBy}
                </label>
                <label className="font-outfit font-normal text-[14px] w-[101.88px] h-[20px]  leading-[16.8px] text-[#0F153E]">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.8789 2.75C8.08545 2.75 4.14482 6.413 3.7606 11.0833H2.80007C2.49619 11.0833 2.22234 11.2667 2.1066 11.5477C1.99086 11.8287 2.05611 12.1517 2.27183 12.3657L3.95164 14.0324C4.24405 14.3225 4.71572 14.3225 5.00813 14.0324L6.68794 12.3657C6.90366 12.1517 6.96891 11.8287 6.85317 11.5477C6.73743 11.2667 6.46358 11.0833 6.15969 11.0833H5.26685C5.64656 7.25167 8.90484 4.25 12.8789 4.25C15.6485 4.25 18.0728 5.70845 19.4156 7.89279C19.6326 8.24565 20.0945 8.35585 20.4473 8.13892C20.8002 7.92199 20.9104 7.46007 20.6935 7.10721C19.0871 4.49427 16.1874 2.75 12.8789 2.75Z"
                      fill="#989EC2"
                    />
                    <path
                      d="M21.6413 10.9666C21.3491 10.6778 20.879 10.6778 20.5868 10.9666L18.9006 12.6333C18.6843 12.8471 18.6185 13.1703 18.7341 13.4517C18.8496 13.733 19.1237 13.9167 19.4278 13.9167H20.3269C19.9457 17.7462 16.676 20.75 12.6829 20.75C9.90039 20.75 7.466 19.2903 6.11809 17.1061C5.90056 16.7536 5.43846 16.6442 5.08596 16.8618C4.73347 17.0793 4.62406 17.5414 4.84159 17.8939C6.4542 20.507 9.36419 22.25 12.6829 22.25C17.4908 22.25 21.4476 18.5892 21.8332 13.9167H22.8003C23.1044 13.9167 23.3785 13.733 23.494 13.4517C23.6096 13.1703 23.5438 12.8471 23.3275 12.6333L21.6413 10.9666Z"
                      fill="#989EC2"
                    />
                    <path
                      d="M9.80005 14.4415C9.80005 15.5165 10.625 16.3831 11.65 16.3831H13.7417C14.6334 16.3831 15.3584 15.6248 15.3584 14.6915C15.3584 13.6748 14.9167 13.3165 14.2584 13.0831L10.9 11.9165C10.2417 11.6831 9.80005 11.3248 9.80005 10.3081C9.80005 9.37479 10.525 8.61646 11.4167 8.61646H13.5084C14.5334 8.61646 15.3584 9.48312 15.3584 10.5581"
                      stroke="#989EC2"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.5735 7.5V17.5"
                      stroke="#989EC2"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </label>
                <label className="font-outfit font-normal text-[14px] w-[109.88px] h-[20px]  leading-[16.8px] text-[#0F153E]">
                  {item.lastUpdateOn}
                </label>
                <span className="w-[90px] h-[17px] cursor-pointer flex justify-start items-center gap-[8px]">
                  <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#9BA1CF]">View Detail</p>
                  <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 7.5L7 1.5M7 1.5H2.5M7 1.5V6"
                      stroke="#9BA1CF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                {selectedRow === item.id && (
                  <div
                    ref={actionModalRef}
                    className="absolute top-[56px] right-0 bg-[#FFFFFF] z-50 w-[308px] h-[76px] rounded-[12px] py-[20px] px-[16px] flex justify-start items-center gap-[16px] border-[1px] border-[#9BA1CF]"
                  >
                    <button className="w-[130px] h-[36px] font-poppins font-normal text-[14px] leading-[20px] text-[#F44336] rounded-[8px] border-[1px] border-[#00000014] bg-white py-[8px] px-[16px] gap-[8px] flex justify-center items-center">
                      Delete
                    </button>
                    <button className="w-[130px] h-[36px] font-poppins font-normal text-[14px] leading-[20px] text-[#000000] rounded-[8px] border-[1px] border-[#00000014] bg-white py-[8px] px-[16px] gap-[8px] flex justify-center items-center">
                      Duplicate
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Offers;
