import React, { useEffect, useRef, useState } from 'react';
// import PasswordSuccessModal from './PasswordSuccessModal';

const EditTeamModal = ({ isModalOpen, setIsModalOpen }) => {
  const modalRef = useRef();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [role, setRole] = useState('admin');
  const [firstName, setFirstName] = useState('Waqas');
  const [originalFirstName] = useState('Waqas');
  const [image, setImage] = useState(null);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  //   Handling image uploaded by user
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
    }
  };

  //   useEffect(() => {
  //     const handleOutsideClick = (event) => {
  //       if (modalRef.current && !modalRef.current.contains(event.target)) {
  //         setIsModalOpen(false);
  //       }
  //     };

  //     document.addEventListener("mousedown", handleOutsideClick);

  //     return () => {
  //       document.removeEventListener("mousedown", handleOutsideClick);
  //     };
  //   }, []);

  return (
    <div
      //   ref={modalRef}
      className="fixed top-0 right-0 inset-0 flex items-center justify-end bg-opacity-50 backdrop-blur-[1px] z-50"
    >
      <div className="flex flex-col justify-center items-center bg-[#fff] overflow-y-auto pt-[200px] pb-[24px] px-[32px] gap-[24px] shadow-lg w-[565px] h-full relative">
        <div className="w-[481px] h-[36px] flex justify-end items-center gap-[30px]">
          {/* <h1 className="font-outfit font-bold text-[26px] leading-[32.76px] text-[#0F153E]">
            Edit Team
          </h1> */}
          <button
            onClick={handleClose}
            className="w-[36px] h-[36px] rounded-[8px] border-[1px] border-[#0000001A] p-[12px] gap-[8px]"
          >
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 4.66688L10.6669 0L12 1.33312L7.33312 6L12 10.6669L10.6669 12L6 7.33312L1.33312 12L0 10.6669L4.66688 6L0 1.33312L1.33312 0L6 4.66688Z"
                fill="#4F4F4F"
              />
            </svg>
          </button>
        </div>
        <div className="w-[481px] h-[724px] flex flex-col justify-start items-center space-y-[20px]">
          <div className="w-full h-[96px] rounded-[64px] border-[1px] border-[#F2F2F2] p-[16px] flex justify-start items-center gap-[16px]">
            <img
              src={image || 'https://via.placeholder.com/64'}
              alt="user"
              className="w-[64px] h-[64px] rounded-[25.28px]"
            />
            <div className="w-[215px] h-[49px] flex flex-col justify-start items-start space-y-[8px]">
              <h1 className="font-albert font-bold text-[20px] leading-[24px] text-[#0F153E]">Waqas Atta</h1>
              <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">Admin</p>
            </div>
            <label htmlFor="file-upload">
              <button className="w-[138px] h-[51px] flex justify-center items-center rounded-[32px] bg-[#F8F8F8] font-albert font-bold text-[14px] leading-[16.8px] text-[#434343]">
                Update Photo
              </button>
            </label>
            <input id="file-upload" type="file" accept="image/*" onChange={handleImageUpload} className="hidden" />
          </div>
        </div>
        <div className="w-[481px] h-[625px] flex flex-col justify-start items-start space-y-[20px]">
          <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
            <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="w-[489px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
              placeholder="Waqas"
            />
          </div>
          <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
            <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Last Name</label>
            <input
              type="text"
              className="w-[489px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
              placeholder="Waqas"
            />
          </div>
          <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
            <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Email Address</label>
            <input
              type="text"
              className="w-[489px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
              placeholder="Waqas"
            />
          </div>
          <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
            <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Phone Number</label>
            <input
              type="text"
              className="w-[489px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
              placeholder="Waqas"
            />
          </div>
          <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
            <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Company Name</label>
            <input
              type="text"
              className="w-[489px] h-[49px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]"
              placeholder="Waqas"
            />
          </div>
          <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
            <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Account Type</label>
            <div className="w-[287px] h-[35px] flex justify-start items-center gap-[20px]">
              <div
                onClick={() => setRole('admin')}
                className="flex justify-start items-center gap-[8px] w-auto h-[35px] rounded-[22px] border-[1px] border-[#F2F2F2] py-[8px] px-[20px]"
              >
                <span className="flex justify-center items-center w-[19px] h-[19px] rounded-[14px] border-[1.58px] border-[#9BA1CF]">
                  <span
                    className={`w-[12.67px] h-[12.67px] rounded-[19px] ${role === 'admin' && 'bg-[#9BA1CF]'}`}
                  ></span>
                </span>
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Admin</p>
              </div>
              <div
                onClick={() => setRole('account-manager')}
                className="flex justify-start items-center gap-[8px] w-auto h-[35px] rounded-[22px] border-[1px] border-[#F2F2F2] py-[8px] px-[10px]"
              >
                <span className="flex justify-center items-center w-[19px] h-[19px] rounded-[14px] border-[1.58px] border-[#9BA1CF]">
                  <span
                    className={`w-[12.67px] h-[12.67px] rounded-[19px] ${role === 'account-manager' && 'bg-[#9BA1CF]'}`}
                  ></span>
                </span>
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Account Manager</p>
              </div>
            </div>
          </div>
          <div className="w-[489px] h-[74px] flex flex-col justify-start items-start space-y-[8px]">
            <label className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
              Commission Percentage
            </label>
            <div className="flex justify-start items-center bg-white w-full h-[49px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px] gap-[8px]">
              <input
                type="number"
                placeholder="20"
                className="w-[423px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] bg-transparent outline-none gap-[8px]"
              />
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.25 3.75L3.75 14.25" stroke="#989EC2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M4.875 6.75C5.91053 6.75 6.75 5.91053 6.75 4.875C6.75 3.83947 5.91053 3 4.875 3C3.83947 3 3 3.83947 3 4.875C3 5.91053 3.83947 6.75 4.875 6.75Z"
                  stroke="#989EC2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.125 15C14.1605 15 15 14.1605 15 13.125C15 12.0895 14.1605 11.25 13.125 11.25C12.0895 11.25 11.25 12.0895 11.25 13.125C11.25 14.1605 12.0895 15 13.125 15Z"
                  stroke="#989EC2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        {firstName !== originalFirstName ? (
          <button className="w-[481px] h-[56px] border-[1px] border-[#9BA1CF] bg-[#9BA1CF] py-[17px] px-[24px] flex justify-center items-center text-[#fff] font-albert font-bold text-[14px] leading-[16.8px] gap-[10px] rounded-[99px]">
            Save Changes
          </button>
        ) : (
          <button className="w-[481px] h-[56px] border-[1px] border-[#9BA1CF] bg-[#fff] py-[17px] px-[24px] flex justify-center items-center text-[#9BA1CF] font-albert font-bold text-[14px] leading-[16.8px] gap-[10px] rounded-[99px]">
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default EditTeamModal;
