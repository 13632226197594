import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Status from 'components/Status/Status';
import axios from 'axios';

const Products = () => {
  const actionModalRef = useRef();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isStatusChecked, setIsstatusChecked] = useState(false);
  const [isStockChecked, setIsStockChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const salesStatus = ['Open', 'Sent', 'Lost', 'Won'];
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedID, setSelectedID] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

  const getAllProducts = async () => {
    try {
      const response = await axios.get(`https://staging.check.firefly.online/products`);
      setProducts(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const deleteProduct = async () => {
    // const selectedIds = Object.keys(selectedCheckboxes).filter(
    //   (id) => selectedCheckboxes[id] === true
    // );
    try {
      const response = await axios.delete(`https://staging.check.firefly.online/products/${selectedID}`);
      setProducts(products.filter((item) => item.id !== selectedID));
      console.log(response);
      alert(`Product Deleted of ID ${selectedID}`);
    } catch (error) {}
  };

  const tableHeadings = ['SKU', 'Categories', 'Current Stock', 'Price', 'Status'];

  const categories = ['Duo Sonic', 'Telecaster Thinline', 'Jazzmaster'];
  const handleCheckboxChange = (id) => {
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const handleCheckAll = () => {
    const newIsAllChecked = !isAllChecked;
    setSelectedCheckboxes(
      products.reduce((acc, product) => {
        acc[product.id] = newIsAllChecked;
        return acc;
      }, {}),
    );
    setIsAllChecked(newIsAllChecked);
  };

  const handleStatusCheckbox = (status) => {
    setIsstatusChecked(status);
  };
  const handleAvailabilityCheckbox = (status) => {
    setIsStockChecked(status);
  };
  return (
    <div className="flex flex-col justify-start items-center space-y-[30px]">
      <div className="flex justify-between items-center gap-[16px] w-[1247px] h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[684px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Products
        </h1>
        <div
          onClick={() => history.push('/add-new-product')}
          className="flex justify-start items-center cursor-pointer gap-[4px] w-[141px] h-[34px] rounded-[8px] bg-[#9BA1CF] py-[8px] px-[20px]"
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.75 9H14.25M9 14.25V3.75"
              stroke="white"
              stroke-width="1.05"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#FFFFFF]">Add Product</p>
        </div>
      </div>
      <div className="w-[1123px] h-[1150px] flex flex-col justify-start items-start space-y-[16px] mr-[60px]">
        <div className="w-[1123px] h-[48px] rounded-[8px] py-[8px] pr-[8px] pl-[16px] flex justify-start items-center gap-[8px] bg-[#F8F8F8]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="#434343"
              stroke-width="1.5"
            />
            <path d="M18.5 18.5L22 22" stroke="#434343" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          <input
            type="text"
            placeholder="Search here"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full bg-transparent outline-none font-poppins font-normal text-[12px] leading-[18px] text-[#434343]"
          />
        </div>
        <div className="w-[1123px] h-[1086px] flex justify-start items-start gap-[24px]">
          <div className="w-[202px] h-screen rounded-[8px] px-[16px] pt-[16px] pb-[162px] flex flex-col justify-start items-start space-y-[24px] border-[1px] border-[#F2F2F2]">
            <h1 className="font-albert font-bold text-[14px] leading-[16.8px] text-[#0F153E]">Filter</h1>
            <div className="w-[170px] border-[1px] border-[#0000000D] "></div>
            <div className="w-[170px] h-[87.27px] flex flex-col justify-start items-start space-y-[16px]">
              <h1 className="font-albert font-bold text-[14px] leading-[16.8px] text-[#0F153E]">Status</h1>
              <div className="w-[142px] h-[38.27px] flex flex-col justify-start items-start space-y-[12px]">
                <div className="w-[56.5px] h-[15.5px] flex justify-start items-center gap-[5px]">
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="checkbox"
                      className="hidden"
                      checked={isStatusChecked}
                      onChange={() => handleStatusCheckbox('active')}
                    />
                    <div
                      className={`w-[20px] h-[20px] rounded-[6px] flex items-center justify-center transition-all duration-200
          ${isStatusChecked === 'active' ? 'bg-[#4640FF] border-[#9BA1CF]' : 'bg-white border-[1px] border-[#989EC2]'}`}
                    >
                      {isStatusChecked === 'active' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 text-[#fff] bg-[#4640FF] rounded-[6px]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      )}
                    </div>
                  </label>
                  <p
                    className={`font-albert text-[12px] leading-[14.4px] ${
                      isStatusChecked === 'active' ? 'text-[#4640FF] font-bold' : 'text-[#989EC2] font-normal'
                    } `}
                  >
                    Active
                  </p>
                </div>
                <div className="w-[56.5px] h-[15.5px] flex justify-start items-center gap-[5px]">
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="checkbox"
                      className="hidden"
                      checked={isStatusChecked}
                      onChange={() => handleStatusCheckbox('inactive')}
                    />
                    <div
                      className={`w-[20px] h-[20px] rounded-[6px] flex items-center justify-center transition-all duration-200
          ${
            isStatusChecked === 'inactive' ? 'bg-[#4640FF] border-[#9BA1CF]' : 'bg-white border-[1px] border-[#989EC2]'
          }`}
                    >
                      {isStatusChecked === 'inactive' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 text-[#fff] bg-[#4640FF] rounded-[6px]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      )}
                    </div>
                  </label>
                  <p
                    className={`font-albert text-[12px] leading-[14.4px] ${
                      isStatusChecked === 'inactive' ? 'text-[#4640FF] font-bold' : 'text-[#989EC2] font-normal'
                    } `}
                  >
                    Inactive
                  </p>
                </div>
              </div>
              <div className="w-[170px] border-[1px] border-[#0000000D] "></div>
            </div>
            <div className="w-[170px] h-[87.27px] flex flex-col justify-start items-start space-y-[16px]">
              <h1 className="font-albert font-bold text-[14px] leading-[16.8px] text-[#0F153E]">Availability</h1>
              <div className="w-[142px] h-[38.27px] flex flex-col justify-start items-start space-y-[8px]">
                <div className="w-[142px] h-[38.97px] flex justify-start items-center gap-[5px]">
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="checkbox"
                      className="hidden"
                      checked={isStockChecked}
                      onChange={() => handleAvailabilityCheckbox('instock')}
                    />
                    <div
                      className={`w-[20px] h-[20px] rounded-[6px] flex items-center justify-center transition-all duration-200
          ${isStockChecked === 'instock' ? 'bg-[#4640FF] border-[#9BA1CF]' : 'bg-white border-[1px] border-[#989EC2]'}`}
                    >
                      {isStockChecked === 'instock' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 text-[#fff] bg-[#4640FF] rounded-[6px]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      )}
                    </div>
                  </label>
                  <p
                    className={`font-albert text-[12px] leading-[14.4px] ${
                      isStockChecked === 'instock' ? 'text-[#4640FF] font-bold' : 'text-[#989EC2] font-normal'
                    } `}
                  >
                    In Stock
                  </p>
                </div>
                <div className="w-[142px] h-[38.97px] flex justify-start items-center gap-[5px]">
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="checkbox"
                      className="hidden"
                      checked={isStockChecked}
                      onChange={() => handleAvailabilityCheckbox('outofstock')}
                    />
                    <div
                      className={`w-[20px] h-[20px] rounded-[6px] flex items-center justify-center transition-all duration-200
          ${
            isStockChecked === 'outofstock' ? 'bg-[#4640FF] border-[#9BA1CF]' : 'bg-white border-[1px] border-[#989EC2]'
          }`}
                    >
                      {isStockChecked === 'outofstock' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 text-[#fff] bg-[#4640FF] rounded-[6px]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      )}
                    </div>
                  </label>
                  <p
                    className={`font-albert text-[12px] leading-[14.4px] ${
                      isStockChecked === 'outofstock' ? 'text-[#4640FF] font-bold' : 'text-[#989EC2] font-normal'
                    } `}
                  >
                    Out of Stock
                  </p>
                </div>
              </div>
              <div className="w-[170px] border-[1px] border-[#0000000D] "></div>
              <div className="w-[170px] h-[341px] flex flex-col justify-start items-start space-y-[16px]">
                <h1 className="font-albert font-bold text-[14px] leading-[16.8px] text-[#0F153E]">Categories</h1>
                <div className="w-[142px] h-[292px] flex flex-col justify-start items-start space-y-[8px]">
                  {categories.map((c) => (
                    <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">{c}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-[897px] h-screen flex flex-col justify-start items-start overflow-y-auto">
            {/* Table Head */}
            <div className="flex justify-start items-start gap-[32px] w-[897px] h-[52px] border-b-[1px] py-[16px] border-[#0000000D]">
              <span className="w-[241px] h-[20px] flex justify-start items-center gap-[16px]">
                <label className="flex items-center space-x-2 cursor-pointer">
                  <input type="checkbox" className="hidden" checked={isAllChecked} onChange={handleCheckAll} />
                  <div
                    className={`w-[20px] h-[20px] rounded-[6px] border-[2px] border-[#D0D5DD] flex items-center justify-center transition-all duration-200
          ${isAllChecked ? 'bg-white border-[#9BA1CF]' : 'bg-white'}`}
                  >
                    {isAllChecked && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 text-[#9BA1CF]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </div>
                </label>
                <label className="font-outfit font-normal text-[14px] leading-[17.64px] text-[#989EC2]">Name</label>
              </span>
              {tableHeadings.map((item, index) => (
                <label className="w-[117.2px] h-[20px] font-outfit font-normal text-[14px] leading-[17.64px] text-[#989EC2]">
                  {item}
                </label>
              ))}
            </div>
            {/* Table Rows */}
            {isLoading
              ? Array(9)
                  .fill(null)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="relative flex justify-start items-center ml-[6px] cursor-pointer gap-[32px] w-[897px] h-[78px] border-b-[1px] py-[16px] border-[#0000000D] animate-pulse"
                    >
                      <span className="w-[204px] h-[46px] flex justify-start items-center gap-[16px]">
                        <div className="w-[20px] h-[20px] rounded-[6px] bg-gray-200"></div>
                        <div className="flex justify-start items-center gap-[8px] w-[205px] h-[46px]">
                          <div className="w-[46px] h-[46px] rounded-[4px] bg-gray-200"></div>
                          <div className="w-[100px] h-[14px] bg-gray-200 rounded"></div>
                        </div>
                      </span>

                      <div className="w-[106.2px] h-[17px] bg-gray-200 rounded"></div>

                      <div className="w-[105.2px] h-[17px] bg-gray-200 rounded"></div>

                      <div className="w-[103.2px] h-[17px] bg-gray-200 rounded"></div>

                      <div className="w-[99.2px] h-[17px] bg-gray-200 rounded"></div>

                      <div className="w-[50px] h-[17px] bg-gray-200 rounded"></div>
                    </div>
                  ))
              : products
                  .filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
                  .map((item, index) => (
                    <div
                      key={item.id}
                      className="relative flex justify-start items-center cursor-pointer gap-[32px] w-[897px] h-[78px] border-b-[1px] py-[16px] border-[#0000000D]"
                    >
                      <span className="w-[217px] h-[46px] flex justify-start items-center gap-[16px]">
                        <label className="flex items-center space-x-2 cursor-pointer">
                          <input
                            type="checkbox"
                            className="hidden"
                            onClick={() => setSelectedID(item.id)}
                            checked={selectedCheckboxes[item.id] || false}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                          <div
                            className={`w-[20px] h-[20px] rounded-[6px] border-[2px] border-[#D0D5DD] flex items-center justify-center transition-all duration-200
              ${isChecked ? 'bg-white border-[#9BA1CF]' : 'bg-white'}`}
                          >
                            {selectedCheckboxes[item.id] && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4 text-[#9BA1CF]"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                            )}
                          </div>
                        </label>
                        <div
                          onClick={() => history.push(`/update-product/${item.id}`)}
                          className="flex justify-start items-center gap-[8px] w-[205px] h-[46px]"
                        >
                          <img
                            src="/assets/client.png"
                            alt="client"
                            className="w-[46px] h-[46px] rounded-[4px] border-[1px] border-[#9BA1CF]"
                          />
                          <h1 className="font-outfit font-bold text-[12px] leading-[14.4px] text-[#0F153E]">
                            {item.name}
                          </h1>
                        </div>
                      </span>
                      <label className="font-albert font-normal text-[14px] w-[106.2px] h-[17px]  leading-[16.8px] text-[#0F153E]">
                        {item.sku}
                      </label>
                      <label className="font-albert font-normal text-[14px] w-[105.2px] h-[17px]  leading-[16.8px] text-[#0F153E]">
                        BIG LEDS
                      </label>
                      <label className="font-albert font-normal text-[14px] w-[103.2px] h-[17px]  leading-[16.8px] text-[#0F153E]">
                        30
                      </label>
                      <label className="font-albert font-normal text-[14px] w-[99.2px] h-[17px]  leading-[16.8px] text-[#0F153E]">
                        €{item.price}
                      </label>
                      <Status status="Active" />
                      {selectedID === item.id && (
                        <div
                          ref={actionModalRef}
                          className="absolute top-[74px] right-8 bg-[#FFFFFF] z-50 w-[308px] h-[76px] rounded-[12px] py-[20px] px-[16px] flex justify-start items-center gap-[16px] border-[1px] border-[#9BA1CF]"
                        >
                          <button
                            onClick={() => deleteProduct()}
                            className="w-[130px] h-[36px] font-poppins font-normal text-[14px] leading-[20px] text-[#F44336] rounded-[8px] border-[1px] border-[#00000014] bg-white py-[8px] px-[16px] gap-[8px] flex justify-center items-center"
                          >
                            Delete
                          </button>
                          <button className="w-[130px] h-[36px] font-poppins font-normal text-[14px] leading-[20px] text-[#000000] rounded-[8px] border-[1px] border-[#00000014] bg-white py-[8px] px-[16px] gap-[8px] flex justify-center items-center">
                            Duplicate
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
