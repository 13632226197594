import React from 'react';
// import LineChart from "../components/graphs/LineChart";
// import DoughnutChart from "../components/graphs/DoughnutChart";

const NewDashboard = () => {
  const saleStatus = [
    {
      title: 'Open',
      color: '#9BA1CF',
    },
    {
      title: 'Sent',
      color: '#FB8A00',
    },
    {
      title: 'Lost',
      color: '#F44336',
    },
    {
      title: 'Won',
      color: '#43A048',
    },
  ];

  const salesStatusMonthly = [
    {
      month: 'January',
      open: 100,
      sent: 60,
      lost: 160,
      won: 50,
    },
    {
      month: 'February',
      open: 100,
      sent: 50,
      lost: 77,
      won: 50,
    },
    {
      month: 'March',
      open: 300,
      sent: 50,
      lost: 50,
      won: 50,
    },
    {
      month: 'April',
      open: 100,
      sent: 50,
      lost: 230,
      won: 50,
    },
    {
      month: 'May',
      open: 100,
      sent: 120,
      lost: 50,
      won: 50,
    },
    {
      month: 'June',
      open: 100,
      sent: 50,
      lost: 50,
      won: 50,
    },
    {
      month: 'July',
      open: 100,
      sent: 50,
      lost: 50,
      won: 50,
    },
    {
      month: 'August',
      open: 100,
      sent: 50,
      lost: 50,
      won: 50,
    },
    {
      month: 'September',
      open: 100,
      sent: 50,
      lost: 50,
      won: 50,
    },
    {
      month: 'October',
      open: 100,
      sent: 50,
      lost: 50,
      won: 50,
    },
    {
      month: 'November',
      open: 100,
      sent: 50,
      lost: 50,
      won: 50,
    },
    {
      month: 'December',
      open: 100,
      sent: 50,
      lost: 50,
      won: 50,
    },
  ];
  return (
    <div className="flex flex-col justify-start items-center space-y-[30px]">
      <div className="flex justify-between items-center gap-[16px] w-[1247px] h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[684px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Dashboard
        </h1>
        <div className="flex justify-start items-center gap-[4px] w-[141px] h-[34px] rounded-[8px] bg-[#9BA1CF] py-[8px] px-[20px]">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.75 9H14.25M9 14.25V3.75"
              stroke="white"
              stroke-width="1.05"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#FFFFFF]">Add Product</p>
        </div>
      </div>
      <div className="w-[1104px] h-auto mb-[30px] flex flex-col justify-start items-start space-y-[30px] mx-auto mr-[100px] ">
        <div className="w-full h-[226px] flex justify-start items-center gap-[38px]">
          <div className="flex flex-col justify-start items-start w-[316px] h-[226px] rounded-[16px] border-[1px] border-[#E5E5E5] p-[10px] space-y-[10px]">
            <div className="w-[296px] h-[98px] py-[18px] px-[23px] flex flex-col justify-center items-start space-y-[10px] rounded-[16px] bg-white shadow-box-shadow">
              <span className="flex flex-col justify-start items-start space-y-[10px] w-[86px] h-[58px]">
                <h3 className="font-albert font-bold text-[14px] w-full leading-[16.8px] text-[#000000]">Sales</h3>
                <h1 className="font-albert font-bold text-[26px] leading-[31.2px] text-[#9BA1CF]">€ 0,00</h1>
              </span>
            </div>
            <div className="w-[296px] h-[98px] py-[18px] px-[23px] flex flex-col justify-center items-start space-y-[10px] rounded-[16px] bg-white shadow-box-shadow">
              <span className="flex flex-col justify-start items-start space-y-[10px] w-full h-[58px]">
                <h3 className="font-albert font-bold text-[14px] w-full leading-[16.8px] text-[#000000]">
                  Total commission earned
                </h3>
                <h1 className="font-albert font-bold text-[26px] leading-[31.2px] text-[#9BA1CF]">€ 0,00</h1>
              </span>
            </div>
          </div>
          <div className="flex justify-start items-start w-[750px] h-[226px] rounded-[16px] border-[1px] border-[#E5E5E5] overflow-hidden gap-[10px]">
            <div className="w-[370px] h-[226px] py-[24px] px-[23px] flex flex-col justify-center items-start space-y-[10px] rounded-[16px] bg-white shadow-box-shadow">
              <h3 className="font-albert font-bold text-[14px] w-[96px] h-[84px] leading-[16.8px] text-[#000000]">
                Savings Rank
              </h3>
              <h1 className="font-albert font-bold text-[12px] leading-[13.8px] text-[#989EC2] w-[324px] h-[84px] text-center">
                Nog geen gewonnen offertes
              </h1>
            </div>
            <div className="w-[370px] h-[226px] py-[24px] px-[23px] flex flex-col justify-center items-start space-y-[10px] rounded-[16px] bg-white shadow-box-shadow">
              <h3 className="font-albert font-bold text-[14px] w-[96px] h-[84px] leading-[16.8px] text-[#000000]">
                Statuses
              </h3>
              <h1 className="font-albert font-bold text-[12px] leading-[13.8px] text-[#989EC2] w-[324px] h-[84px] text-center">
                Nog geen gewonnen offertes
              </h1>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start w-full h-[226px] border-[1px] border-[#E5E5E5] rounded-[16px] p-[32px] space-y-[10px]">
          <p className="font-albert font-bold text-[14px] leading-[16.8px] text-[#000000]">
            Revenue & Monthly service costs
          </p>
          <div className="w-[1040px] h-[135px] flex justify-center items-center">
            <p className="font-albert font-bold text-[12px] leading-[14.2px] text-[#989EC2]">No Offers Sold Yet</p>
          </div>
        </div>
        <div className="w-[1104px] h-[417px] flex flex-col justify-start items-start rounded-[16px] border-[0.85px] border-[#F2F2F2] p-[20.3px] space-y-[27.06px] bg-[#FFFFFF]">
          <div className="flex justify-between items-center gap-[8px] w-[1063.41px] h-[30px]">
            <p className="font-poppins font-semibold text-[16px] leading-[24px] text-[#434343] w-[581.85px] h-[24px]">
              Sale Summary
            </p>
            <div className="w-[338px] h-[30px] flex justify-start items-center gap-[8px]">
              {saleStatus.map((item, index) => (
                <div
                  key={index}
                  className="w-auto h-[30px] py-[8px] px-[16px] rounded-[8px] flex justify-start items-center gap-[10px] bg-[#F8F8F8]"
                >
                  <span className={`w-[10px] h-[10px] rounded-[6.77px]`} style={{ backgroundColor: item.color }}></span>
                  <p>{item.title}</p>
                </div>
              ))}
            </div>
            <span className="w-[77.56px] h-[24.52px] py-[6.77px] px-[8px] cursor-pointer rounded-[6.77px] border-[1px] border-[#0000000D] bg-white flex justify-center items-center gap-[5.53px]">
              <p className="font-poppins font-normal text-[12px] leading-[18px] text-[#585858]">Weekly</p>
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.6713 5.47607L7.42529 9.11548L3.17932 5.47607"
                  stroke="#585858"
                  stroke-width="0.909851"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div className="w-[1063.41px] h-[319.35px] flex justify-start items-center gap-[4px]">
            <div className="w-[23px] h-[319.35px] flex flex-col justify-between items-center pb-[35.52px]">
              <p className="font-poppins font-normal text-[12px] leading-[18px] text-[#434343]">400</p>
              <p className="font-poppins font-normal text-[12px] leading-[18px] text-[#434343]">300</p>
              <p className="font-poppins font-normal text-[12px] leading-[18px] text-[#434343]">200</p>
              <p className="font-poppins font-normal text-[12px] leading-[18px] text-[#434343]">100</p>
              <p className="font-poppins font-normal text-[12px] leading-[18px] text-[#434343]">0</p>
            </div>
            {salesStatusMonthly.map((item, index) => (
              <div
                key={index}
                className="w-[82.77px] h-[319.35px] flex flex-col justify-start items-center gap-[16.91px]"
              >
                <div className="w-[51.93px] h-[284.43px] flex justify-start items-center gap-[8px]">
                  <span className="relative w-[8.18px] h-[284.43px] rounded-[6.77px] bg-[#F2F2F2]">
                    <span
                      className={`w-full rounded-[6.77px] bg-[#F8B785] absolute bottom-0`}
                      style={{ height: `${item.sent}px` }}
                    ></span>
                  </span>
                  <span className="relative w-[8.18px] h-[284.43px] rounded-[6.77px] bg-[#F2F2F2]">
                    <span
                      className="w-full  rounded-[6.77px] bg-[#F44336] absolute bottom-0"
                      style={{ height: `${item.lost}px` }}
                    ></span>
                  </span>
                  <span className="relative w-[8.18px] h-[284.43px] rounded-[6.77px] bg-[#F2F2F2]">
                    <span
                      className="w-full h-[172.04px] rounded-[6.77px] bg-[#43A048] absolute bottom-0"
                      style={{ height: `${item.won}px` }}
                    ></span>
                  </span>
                  <span className="relative w-[8.18px] h-[284.43px] rounded-[6.77px] bg-[#F2F2F2]">
                    <span
                      className="w-full h-[172.04px] rounded-[6.77px] bg-[#9BA1CF] absolute bottom-0"
                      style={{ height: `${item.open}px` }}
                    ></span>
                  </span>
                </div>
                <label className="font-poppins font-normal text-[12px] leading-[18px] text-[#434343]">
                  {item.month}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="w-[1104px] h-[442px] flex justify-start items-center gap-[30px]">
          <div className="flex flex-col justify-start items-start space-y-[55.2px] w-[374px] h-full border-[0.85px] border-[#F2F2F2] rounded-[16px]">
            <h1 className="font-poppins font-semibold text-[16px] leading-[24px] text-[#434343] w-[333.41px] h-[24px] mx-auto mt-[20px]">
              Number of Products Sold
            </h1>
            {/* <DoughnutChart /> */}
          </div>
          <div className="flex flex-col justify-start items-start w-[700px] h-full border-[0.85px] border-[#F2F2F2] rounded-[16px] p-[20.3px] gap-[27.06px]">
            <div className="659.41px] h-[24.52px] flex justify-start items-center gap-[8px]">
              <h1 className="font-poppins font-semibold text-[16px] leading-[24px] text-[#434343] w-[573.85px] h-[24px]">
                Commission
              </h1>
              <span className="w-[77.56px] h-[24.52px] py-[6.77px] px-[8px] cursor-pointer rounded-[6.77px] border-[1px] border-[#0000000D] bg-white flex justify-center items-center gap-[5.53px]">
                <p className="font-poppins font-normal text-[12px] leading-[18px] text-[#585858]">Weekly</p>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.6713 5.47607L7.42529 9.11548L3.17932 5.47607"
                    stroke="#585858"
                    stroke-width="0.909851"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            {/* <LineChart /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDashboard;
