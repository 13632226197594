import React from 'react';

const Status = ({ status }) => {
  return (
    <label
      className={`w-[46px] h-[20px] rounded-[33px] py-[4px] px-[8px] font-outfit font-normal text-[10px] leading-[12px] ${
        status === 'Active' ? 'text-[#55833B] bg-[#55833B1A]' : 'text-[#FF8000] bg-[#FF7F000D]'
      }`}
    >
      {status}
    </label>
  );
};

export default Status;
