import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Status from 'components/Status/Status';
// import EditTeamModal from "../components/modals/EditTeamModal";

const Clients = () => {
  const actionModalRef = useRef();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const salesStatus = ['Open', 'Sent', 'Lost', 'Won'];
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tableHeadings = ['Email Address', 'Company', ''];
  const data = [
    {
      id: 1,
      name: 'Katna Beatrix',
      email: 'willie.jennings@example.com',
      role: 'Admin',
      status: 'Active',
    },
    {
      id: 2,
      name: 'Waqas Atta',
      email: 'willie.jennings@example.com',
      role: 'Account Manager',
      status: 'Active',
    },
    {
      id: 3,
      name: 'Waqas Atta',
      email: 'willie.jennings@example.com',
      role: 'Account Manager',
      status: 'Inactive',
    },
  ];

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  //   Closing modal on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (actionModalRef.current && !actionModalRef.current.contains(event.target)) {
        setSelectedRow(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  return (
    <div className="flex flex-col justify-start items-center space-y-[30px]">
      <div className="flex justify-between items-center gap-[16px] w-[1247px] h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[684px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Clients
        </h1>

        <div
          onClick={() => history.push('/add-new-client')}
          className="flex justify-start items-center cursor-pointer gap-[4px] w-[141px] h-[34px] rounded-[8px] bg-[#9BA1CF] py-[8px] px-[15px]"
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.75 9H14.25M9 14.25V3.75"
              stroke="white"
              stroke-width="1.05"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#FFFFFF]">Add Client</p>
        </div>
      </div>
      <div className="w-[1123px] h-[746px] flex flex-col justify-start items-start space-y-[16px] mr-[60px]">
        <div className="flex flex-col justify-start items-start w-[1123px] h-[659px]">
          {/* Table Head */}
          <div className="flex justify-start items-start gap-[44px] w-full h-[56px] border-b-[1px] py-[16px] border-[#0000000D]">
            <span className="w-[290px] h-[20px] flex justify-start items-center gap-[16px]">
              <label className="flex items-center space-x-2 cursor-pointer">
                <input type="checkbox" className="hidden" checked={isChecked} onChange={handleCheckboxChange} />
                <div
                  className={`w-[20px] h-[20px] rounded-[6px] border-[2px] border-[#D0D5DD] flex items-center justify-center transition-all duration-200
          ${isChecked ? 'bg-white border-[#9BA1CF]' : 'bg-white'}`}
                >
                  {isChecked && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 text-[#9BA1CF]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </div>
              </label>
              <label className="font-outfit font-normal text-[14px] leading-[17.64px] text-[#989EC2]">Name</label>
            </span>
            {tableHeadings.map((item, index) => (
              <label className="w-[190.67px] h-[20px] font-outfit font-normal text-[14px] leading-[17.64px] text-[#989EC2]">
                {item}
              </label>
            ))}
          </div>
          {/* Table Rows */}
          {data.map((item, index) => (
            <div
              key={item.id}
              // onClick={() => setSelectedRow(item.id)}
              className="relative flex justify-start items-center cursor-pointer gap-[32px] w-[1123px] h-[78px] border-b-[1px] py-[16px] border-[#0000000D]"
            >
              <span className="w-[260px] h-[17px] flex justify-start items-center gap-[16px]">
                <label className="flex items-center space-x-2 cursor-pointer">
                  <input type="checkbox" className="hidden" checked={isChecked} onChange={handleCheckboxChange} />
                  <div
                    className={`w-[20px] h-[20px] rounded-[6px] border-[2px] border-[#D0D5DD] flex items-center justify-center transition-all duration-200
          ${isChecked ? 'bg-white border-[#9BA1CF]' : 'bg-white'}`}
                  >
                    {isChecked && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 text-[#9BA1CF]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </div>
                </label>
                <div className="w-[205px] h-[46px] flex justify-start items-center gap-[8px]">
                  <img
                    src="/assets/client.png"
                    alt="client"
                    className="w-[46px] h-[46px] rounded-[4px] border-[1px] border-[#9BA1CF]"
                  />
                  <p className="font-albert font-bold text-[12px] leading-[14.4px] text-[#0F153E]">{item.name}</p>
                </div>
              </span>
              <label className="font-outfit font-normal text-[14px] w-[180.67px] h-[17px]  leading-[16.8px] text-[#0F153E]">
                {item.email}
              </label>
              <label className="font-outfit font-normal text-[14px] w-[170.67px] h-[17px]  leading-[16.8px] text-[#0F153E]">
                eBay
              </label>
              <label className="font-outfit font-normal text-[14px] w-[175.67px] h-[17px]   leading-[16.8px] text-[#0F153E]">
                30
              </label>
              <label className="font-outfit font-normal text-[14px] w-[175.67px] h-[17px]   leading-[16.8px] text-[#0F153E]">
                €499
              </label>
              <div className="w-[190.67px] h-[17px]  leading-[17.64px]">
                <Status status={item.status} />
              </div>

              {/* <span
                onClick={() => setIsModalOpen(true)}
                className="w-[90px] h-[17px] cursor-pointer flex justify-start items-center gap-[8px]"
              >
                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#9BA1CF]">
                  View Detail
                </p>
                <svg
                  width="8"
                  height="9"
                  viewBox="0 0 8 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 7.5L7 1.5M7 1.5H2.5M7 1.5V6"
                    stroke="#9BA1CF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {isModalOpen && (
                  <EditTeamModal setIsModalOpen={setIsModalOpen} />
                )}
              </span> */}
              {/* {selectedRow === item.id && (
                <div
                  ref={actionModalRef}
                  className="absolute top-[56px] right-0 bg-[#FFFFFF] z-50 w-[308px] h-[76px] rounded-[12px] py-[20px] px-[16px] flex justify-start items-center gap-[16px] border-[1px] border-[#9BA1CF]"
                >
                  <button className="w-[130px] h-[36px] font-poppins font-normal text-[14px] leading-[20px] text-[#F44336] rounded-[8px] border-[1px] border-[#00000014] bg-white py-[8px] px-[16px] gap-[8px] flex justify-center items-center">
                    Delete
                  </button>
                  <button className="w-[130px] h-[36px] font-poppins font-normal text-[14px] leading-[20px] text-[#000000] rounded-[8px] border-[1px] border-[#00000014] bg-white py-[8px] px-[16px] gap-[8px] flex justify-center items-center">
                    Duplicate
                  </button>
                </div>
              )} */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
