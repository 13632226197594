import React, { useState } from 'react';

const AddNewOffer = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [quotationID, setQuotationID] = useState('aed8eb2f-5b47-402b-b95b-5c9ee092584c');
  const [copied, setCopied] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(true);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(quotationID).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };

  const toggle = () => {
    setIsToggled(!isToggled);
  };

  const history = [
    {
      id: 1,
      createdBy: 'FRANK VISSER',
      date: '08 January 2025',
      time: '12.14',
    },
    {
      id: 2,
      createdBy: 'FRANK VISSER',
      date: '08 January 2025',
      time: '12.14',
    },
  ];
  const savingsOverview = [
    {
      id: 1,
      title: 'Savings per year',
      value: '0 kWh ⋅ € 0,00',
    },
    {
      id: 2,
      title: 'Return in 5 years',
      value: '∞ %',
    },
    {
      id: 3,
      title: 'Return in 5 years',
      value: '∞ years',
    },
    {
      id: 4,
      title: 'Total annual savings upon replacement',
      value: '€ 0,00',
    },
    {
      id: 5,
      title: 'Investment in replacement',
      value: '€ 0,00',
    },
  ];
  return (
    <div className="flex flex-col justify-start items-center space-y-[30px] bg-gray-100 pb-8">
      <div className="flex justify-between items-center gap-[16px] bg-white w-full h-[80px] border-b-[1px] border-[#E5E5E5] py-[16px] pr-[100px] pl-[32px]">
        <h1 className="flex items-center w-[868px] h-[48px] font-albert font-bold text-[26px] leading-[31.2px] text-[#121212]">
          Add New Offer
        </h1>

        <div className="flex justify-center items-center gap-[10px] w-[100px] h-[30px] rounded-[8px] bg-[#F8F8F8] py-[8px] px-[16px]">
          <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#000000]">Frank Visser</p>
        </div>
        <div className="flex justify-start items-center gap-[8px] w-[115px] h-[40px] rounded-[8px] bg-[#9BA1CF] py-[8px] px-[24px]">
          <p className="font-albert font-semibold text-[14px] leading-[21px] text-[#FFFFFF]">Sync</p>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="flex justify-start items-start gap-[40px] mx-auto mr-[88px]">
        {/* First Column */}
        <div className="w-[688px] h-auto flex flex-col justify-start items-start space-y-[32px]">
          <div
            className={`flex flex-col justify-start items-start space-y-[16px] w-full ${
              isContactOpen ? 'h-[305px]' : 'h-[56px]'
            } border-[1px] bg-white border-[#F2F2F2] rounded-[16px] p-[32px] gap-[10px]`}
          >
            <div className="w-[624px] h-[241px] flex justify-between items-start gap-[32px]">
              <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">Select a Contact</p>
              {isContactOpen ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setIsContactOpen(false)}
                  className="cursor-pointer"
                >
                  <path
                    d="M5 15L12 9L19 15"
                    stroke="#434343"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19 9L12 15L5 9"
                    stroke="#434343"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    onClick={() => setIsContactOpen(true)}
                    className="cursor-pointer"
                  />
                </svg>
              )}
            </div>
            <div
              className={`w-[588px] h-[201px] ${
                isContactOpen ? 'flex' : 'hidden'
              } justify-between items-start gap-[65px]`}
            >
              <div className="w-[416px] h-[201px] flex flex-col justify-start items-start space-y-[20px]">
                <div className="w-[416px] h-[107px] flex flex-col justify-start items-start space-y-[8px]">
                  <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                    Select a Contact
                  </p>
                  <div className="w-[416px] h-[50px] flex justify-start items-center gap-[8px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px]">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Search by first, last or company name"
                      className="outline-none bg-white w-[358px] h-[17px]"
                    />
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.25 6.75L9 11.25L3.75 6.75"
                        stroke="#CCCCCC"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="w-[143px] h-[24px] flex justify-start items-center gap-[9px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5 12H19M12 19V5"
                        stroke="#989EC2"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                      Add new contact
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-start items-start w-[416px] h-[74px] gap-[8px]">
                  <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                    Project Location Details
                  </p>
                  <div className="w-[416px] h-[50px] flex justify-start items-center gap-[8px] rounded-[4px] border-[1px] border-[#E5E5E5] p-[16px]">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Enter the project location"
                      className="outline-none bg-white w-full h-[17px]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start w-[107px] h-[71px] space-y-[12px]">
                <p className="font-albert font-bold text-[14px] leading-[16.8px] text-[#0F153E]">Firefly Projects</p>
                <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#0F153E] w-[107px] h-[42px]">
                  Frank Visser Leemolen 68 2678 MH De Lier
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center w-full h-[56px] border-[1px] bg-white border-[#F2F2F2] rounded-[16px] py-[16px] px-[32px]">
            <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">Quotation</p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 9L12 15L5 9"
                stroke="#434343"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="flex justify-between items-center w-full h-[56px] border-[1px] bg-white border-[#F2F2F2] rounded-[16px] py-[16px] px-[32px]">
            <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">Energy Consumption</p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 9L12 15L5 9"
                stroke="#434343"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="flex justify-between items-center w-full h-[56px] border-[1px] bg-white border-[#F2F2F2] rounded-[16px] py-[16px] px-[32px]">
            <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">Room Details</p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 9L12 15L5 9"
                stroke="#434343"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        {/* Second Column */}
        <div className="w-[411px] h-auto flex flex-col justify-start items-start space-y-[32px]">
          <div className="flex justify-center items-center w-full h-[340px] border-[1px] border-[#F2F2F2] bg-[#9BA1CF] rounded-[16px] py-[50px] px-[24px] gap-[10px]">
            <div className="flex flex-col justify-start items-start w-[343px] h-[240px] space-y-[20px]">
              <div className="w-full h-[29px] flex justify-between items-center">
                <div className="w-[236px] h-[29px] flex justify-start items-center gap-[8px]">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0833 16.75H7.91667M12.0833 16.75C12.0833 16.1553 12.0833 15.858 12.1151 15.6608C12.2174 15.0246 12.2353 14.9845 12.641 14.4839C12.7668 14.3287 13.2338 13.9105 14.1677 13.0743C15.4457 11.9299 16.25 10.2672 16.25 8.41663C16.25 4.96485 13.4518 2.16663 10 2.16663C6.54822 2.16663 3.75 4.96485 3.75 8.41663C3.75 10.2672 4.55425 11.9299 5.8323 13.0743C6.76621 13.9105 7.23322 14.3287 7.35902 14.4839C7.76468 14.9845 7.78258 15.0246 7.88493 15.6608C7.91667 15.858 7.91667 16.1553 7.91667 16.75M12.0833 16.75C12.0833 17.5288 12.0833 17.9182 11.9159 18.2083C11.8062 18.3983 11.6484 18.5561 11.4583 18.6658C11.1683 18.8333 10.7788 18.8333 10 18.8333C9.22115 18.8333 8.83173 18.8333 8.54167 18.6658C8.35164 18.5561 8.19385 18.3983 8.08413 18.2083C7.91667 17.9182 7.91667 17.5288 7.91667 16.75"
                      stroke="#F3F3F6"
                      stroke-width="1.5"
                    />
                    <path d="M10 14.6667V13" stroke="#F3F3F6" stroke-width="1.5" stroke-linecap="round" />
                    <path
                      d="M11.4438 12.1666C11.1556 12.6648 10.617 13 10.0001 13C9.38319 13 8.84457 12.6648 8.5564 12.1666"
                      stroke="#F3F3F6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                  <h1 className="font-albert font-bold text-[24px] leading-[28.8px] text-[#F3F3F6]">
                    Savings Overview
                  </h1>
                </div>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.2599 4.09997L5.04985 12.79C4.73985 13.12 4.43985 13.77 4.37985 14.22L4.00985 17.46C3.87985 18.63 4.71985 19.43 5.87985 19.23L9.09985 18.68C9.54985 18.6 10.1799 18.27 10.4899 17.93L18.6999 9.23997C20.1199 7.73997 20.7599 6.02997 18.5499 3.93997C16.3499 1.86997 14.6799 2.59997 13.2599 4.09997Z"
                    stroke="#F3F3F6"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8901 5.55005C12.3201 8.31005 14.5601 10.42 17.3401 10.7"
                    stroke="#F3F3F6"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3 22.5H21"
                    stroke="#F3F3F6"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="w-[343px] h-[191px] flex flex-col justify-start items-start space-y-[12px]">
                {savingsOverview.map((saving) => (
                  <div
                    key={saving.id}
                    className="w-[343px] h-[191px] flex flex-col justify-start items-start space-y-[12px]"
                  >
                    <div className="w-[343px] h-[19px] flex justify-between items-center">
                      <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#F3F3F6] ">
                        {saving.title}
                      </p>
                      <p className="font-albert font-bold text-[16px] leading-[19.2px] text-[#F3F3F6] ">
                        {saving.value}
                      </p>
                    </div>
                    <div className="w-full border-[1px] border-[#F8F8F866]"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-[416px] h-[1156px] flex flex-col justify-start items-start space-y-[32px]">
            <div className="w-full h-[250px] flex flex-col justify-start items-start space-y-[16px]">
              <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">Payment Method</p>
              <div className="w-full h-[210px] flex flex-col justify-start items-start space-y-[32px]">
                <div className="w-[366px] h-[49px] flex justify-center items-center mx-auto">
                  <button className="w-[183px] h-[49px] bg-[#FFFFFF] font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] rounded-tl-[4px] rounded-bl-[4px] border-t-[1px] border-b-[1px] border-l-[1px] border-[#E5E5E5] p-[16px] gap-[8px]">
                    Buy
                  </button>
                  <button className="w-[183px] h-[49px] bg-[#9BA1CF] font-albert font-normal text-[14px] leading-[16.8px] text-[#F3F3F6] rounded-tr-[4px] rounded-br-[4px] border-t-[1px] border-b-[1px] border-r-[1px] border-[#E5E5E5] p-[16px] gap-[8px]">
                    Light as Service
                  </button>
                </div>
                <div className="flex flex-col justify-start items-start w-full h-[129px] space-y-[24px]">
                  <div className="w-full h-[129px] flex justify-between items-start gap-[12px]">
                    <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                      Standard monthly amount
                    </p>
                    <p className="font-albert font-bold text-[16px] leading-[19.2px] text-[#121212]">€ 0,00</p>
                  </div>
                  <div className="w-full border-[1px] border-[#0000001A]"></div>
                  <div className="w-full h-[74px] flex flex-col justify-start items-start space-y-[8px]">
                    <div className="w-full h-[30px] flex justify-between items-center">
                      <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">
                        Transfer monthly amount
                      </p>
                      <button className="flex items-center justify-center p-2 cursor-pointer" onClick={toggle}>
                        <div
                          className={`relative w-[25px] h-[20.35px] rounded-full transition-colors duration-300 ease-in-out ${
                            isToggled ? 'bg-[#989EC2]' : 'bg-gray-200'
                          }`}
                        >
                          <div
                            className={`absolute top-[4px] left-[0px] w-[14px] h-[14px] bg-white rounded-full transition-all duration-300 ease-in-out ${
                              isToggled ? 'translate-x-3' : 'translate-x-0'
                            }`}
                          />
                        </div>
                      </button>
                    </div>
                    <div className="flex justify-start items-center w-full h-[36px] rounded-[4px] border-[1px] border-[#E5E5E5] bg-white gap-[8px] p-[16px]">
                      <input
                        type="text"
                        placeholder="Selected Monthly Amount"
                        className="outline-none bg-white w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-[32px] w-full h-[874px]">
              <div className="flex flex-col justify-start items-start space-y-[16px] w-full h-[114px]">
                <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">Quote ID for Sync</p>
                <div className="flex flex-col justify-start items-start space-y-[8px] w-full h-[74px]">
                  <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Quotation ID</p>
                  <div className="flex justify-start items-center gap-[8px] border-[1px] border-[#E5E5E5] bg-white w-full h-[49px] p-[16px]">
                    <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] w-[352px] h-[17px]">
                      {quotationID}
                    </p>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={handleCopyClick}
                    >
                      <path
                        d="M16 13.4V17.6C16 21.1 14.6 22.5 11.1 22.5H6.9C3.4 22.5 2 21.1 2 17.6V13.4C2 9.9 3.4 8.5 6.9 8.5H11.1C14.6 8.5 16 9.9 16 13.4Z"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 7.4V11.6C22 15.1 20.6 16.5 17.1 16.5H16V13.4C16 9.9 14.6 8.5 11.1 8.5H8V7.4C8 3.9 9.4 2.5 12.9 2.5H17.1C20.6 2.5 22 3.9 22 7.4Z"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  {/* Showing Pop up after copied quotation ID */}
                  {copied && (
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#9BA1CF] text-white px-4 py-2 rounded-md">
                      Copied!
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[16px] w-full h-[114px]">
                <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">Layout</p>
                <div className="flex flex-col justify-start items-start space-y-[8px] w-full h-[74px]">
                  <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Select Layout</p>
                  <div className="flex justify-start items-center gap-[8px] border-[1px] border-[#E5E5E5] bg-white w-full h-[49px] p-[16px]">
                    <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] w-[352px] h-[17px]">
                      CityTech
                    </p>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.25 6.75L9 11.25L3.75 6.75"
                        stroke="#CCCCCC"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[8px] w-full h-[74px]">
                <div className="flex justify-between items-center w-full">
                  <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">PDF</p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.55 21.67C18.84 20.54 22 16.64 22 12C22 6.48 17.56 2 12 2C5.33 2 2 7.56 2 7.56M2 7.56V3M2 7.56H4.01H6.44"
                      stroke="#0F153E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 12C2 17.52 6.48 22 12 22"
                      stroke="#0F153E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-dasharray="3 3"
                    />
                  </svg>
                </div>

                <div className="flex justify-start items-center gap-[8px] border-[1px] border-[#E5E5E5] bg-white w-full h-[49px] p-[16px]">
                  <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2] w-[312px] h-[17px]">
                    {quotationID}
                  </p>
                  <div className="w-[64px] h-[24px] flex justify-start items-center gap-[16px]">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={handleCopyClick}
                    >
                      <path
                        d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
                        stroke="#989EC2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z"
                        fill="#989EC2"
                      />
                      <path
                        d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z"
                        fill="#989EC2"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-start items-start space-y-[17px] w-full h-[58px]">
                <div className="flex justify-between items-center w-full">
                  <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">
                    Internal Cost Center Number
                  </p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.2599 3.6L5.04985 12.29C4.73985 12.62 4.43985 13.27 4.37985 13.72L4.00985 16.96C3.87985 18.13 4.71985 18.93 5.87985 18.73L9.09985 18.18C9.54985 18.1 10.1799 17.77 10.4899 17.43L18.6999 8.74C20.1199 7.24 20.7599 5.53 18.5499 3.44C16.3499 1.37 14.6799 2.1 13.2599 3.6Z"
                      stroke="#0F153E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.8901 5.04999C12.3201 7.80999 14.5601 9.91999 17.3401 10.2"
                      stroke="#0F153E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 22H21"
                      stroke="#0F153E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#CCCCCC] w-full h-[17px]">
                  No Cost Center Number Known
                </p>
              </div>
              <div className="flex flex-col justify-start items-start space-y-[17px] w-full h-[58px]">
                <div className="flex justify-between items-center w-full">
                  <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">Internal Note</p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.2599 3.6L5.04985 12.29C4.73985 12.62 4.43985 13.27 4.37985 13.72L4.00985 16.96C3.87985 18.13 4.71985 18.93 5.87985 18.73L9.09985 18.18C9.54985 18.1 10.1799 17.77 10.4899 17.43L18.6999 8.74C20.1199 7.24 20.7599 5.53 18.5499 3.44C16.3499 1.37 14.6799 2.1 13.2599 3.6Z"
                      stroke="#0F153E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.8901 5.04999C12.3201 7.80999 14.5601 9.91999 17.3401 10.2"
                      stroke="#0F153E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 22H21"
                      stroke="#0F153E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#CCCCCC] w-full h-[17px]">
                  No Note
                </p>
              </div>
              <div className="w-full h-[79px] flex flex-col justify-start items-start space-y-[17px]">
                <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343]">Commission</p>
                <div className="w-full h-[38px] flex justify-between items-center">
                  <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#0F153E]">0%</p>
                  <p className="font-albert font-bold text-[16px] leading-[19.2px] text-[#121212]">€ 0,00</p>
                </div>
              </div>
              <div className="w-full h-[184px] flex flex-col justify-start items-start space-y-[17px]">
                <p className="font-albert font-bold text-[20px] leading-[24px] text-[#434343] w-full h-[27px]">
                  History
                </p>
                {history.map((histroy, index) => (
                  <div className="w-full h-[140px] flex flex-col justify-start items-start space-y-[8px]">
                    <div className="w-[416px] h-[66px] flex justify-start items-start gap-[13px]">
                      <div className="w-[14px] h-[66px] flex flex-col justify-center items-center space-y-[8px]">
                        <span className="w-[14px] h-[14px] bg-[#868686] rounded-full"></span>
                        <span className="w-[2px] h-[44px] bg-[#868686]"></span>
                      </div>
                      <div className="w-[199px] h-[43px] flex flex-col justify-start items-start gap-[12px]">
                        <div className="w-[152px] h-[14px] flex justify-start items-center gap-[10px]">
                          <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#868686]">
                            {histroy.date}
                          </p>
                          <span className="w-[8px] h-[8px] bg-[#868686] rounded-full"></span>
                          <p className="font-albert font-normal text-[12px] leading-[14.4px] text-[#868686]">
                            {histroy.time}
                          </p>
                        </div>
                        <div className="w-[199px] h-[17px] flex justify-start items-center gap-[18px]">
                          <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#0F153E]">Created</p>
                          <p className="font-albert font-normal text-[14px] leading-[16.8px] text-[#989EC2]">
                            {histroy.createdBy}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewOffer;
