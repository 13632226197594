import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
// import logo from "../../assets/logo.png";
import { useHistory } from 'react-router';

const Sidebar = () => {
  const history = useHistory();
  const modalRef = useRef();

  const [isDasboardSelected, setIsDashboardSelected] = useState(true);
  const [isOffersSelected, setIsOffersSelected] = useState(false);
  const [isClientsSelected, setIsClientsSelected] = useState(false);
  const [isProductsSelected, setIsProductsSelected] = useState(false);
  const [isTeamSelected, setIsTeamSelected] = useState(false);
  const [isAdminSelected, setIsAdminSeleted] = useState(false);
  const [isDeviceSelected, setIsDeviceSeleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
    history.push('/');
    setIsModalOpen(false);
  };
  const handleSettings = () => {
    history.push('/profile');
    setIsModalOpen(false);
    // pipelines
  };

  return (
    <div className="flex flex-col justify-start items-start space-y-[70px] w-[265px] h-[913px] bg-[#000000] py-[24px]">
      <div className="flex flex-col justify-start items-center space-y-[64px] w-[265px] h-[565.96px]">
        <svg width="121" height="35" viewBox="0 0 121 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M61.166 16.5196C61.382 15.0314 61.964 13.8599 62.978 12.9574C63.992 12.0012 65.324 11.523 66.818 11.523C66.926 11.523 67.034 11.523 67.136 11.523V9.2937C67.028 9.2937 66.92 9.2937 66.818 9.2937C64.364 9.2937 62.336 10.1424 60.788 11.792C59.348 13.334 58.652 15.3003 58.598 17.691H61.106"
            fill="white"
          />
          <path
            d="M84.3741 17.745C84.3741 15.3005 83.6301 13.2803 82.1841 11.6846C80.6361 10.035 78.6081 9.18628 76.1001 9.18628C73.5921 9.18628 71.564 10.035 70.016 11.6846C68.522 13.2803 67.826 15.3005 67.826 17.7988C67.826 20.297 68.5701 22.2634 70.1181 23.913C71.7201 25.5626 73.7481 26.4113 76.2561 26.4113C78.2301 26.4113 79.8861 25.9331 81.1101 25.0306C82.3401 24.1281 83.3 22.9029 83.882 21.3609H81.1581C80.1981 23.2197 78.5421 24.176 76.3041 24.176C74.6481 24.176 73.3161 23.644 72.2481 22.634C71.1801 21.6239 70.592 20.3508 70.436 18.8626H84.416L84.3741 17.745ZM70.3941 16.4181C70.6101 14.9299 71.192 13.7585 72.206 12.856C73.274 11.8997 74.5521 11.4216 76.1001 11.4216C77.6481 11.4216 78.9261 11.8997 79.9941 12.856C80.9541 13.7585 81.5961 14.9299 81.8061 16.4181C81.758 16.4181 70.3941 16.4181 70.3941 16.4181Z"
            fill="white"
          />
          <path
            d="M89.7081 3.65773C88.5861 4.56022 88.0521 5.94084 88.0521 7.80559V26.0884H90.5601V11.4753H93.3861V9.24598H90.5601V7.97294C90.5601 6.80149 90.8781 5.9528 91.4661 5.42087C92.0541 4.88894 92.9601 4.62596 94.1361 4.62596H94.4541V2.2771H94.2381C92.3241 2.2771 90.7761 2.75524 89.7081 3.65773Z"
            fill="white"
          />
          <path d="M100.01 2.54614H97.5021V26.0407H100.01V2.54614Z" fill="white" />
          <path
            d="M116.546 9.23999L110.996 23.0044L105.554 9.23999H102.782L109.664 25.8255L106.568 32.6808H109.292L119.27 9.23999H116.546Z"
            fill="white"
          />
          <path
            d="M84.3741 17.745C84.3741 15.3005 83.6301 13.2803 82.1841 11.6846C80.6361 10.035 78.6081 9.18628 76.1001 9.18628C73.5921 9.18628 71.564 10.035 70.016 11.6846C68.522 13.2803 67.826 15.3005 67.826 17.7988C67.826 20.297 68.5701 22.2634 70.1181 23.913C71.7201 25.5626 73.7481 26.4113 76.2561 26.4113C78.2301 26.4113 79.8861 25.9331 81.1101 25.0306C82.3401 24.1281 83.3 22.9029 83.882 21.3609H81.1581C80.1981 23.2197 78.5421 24.176 76.3041 24.176C74.6481 24.176 73.3161 23.644 72.2481 22.634C71.1801 21.6239 70.592 20.3508 70.436 18.8626H84.416L84.3741 17.745ZM70.3941 16.4181C70.6101 14.9299 71.192 13.7585 72.206 12.856C73.274 11.8997 74.5521 11.4216 76.1001 11.4216C77.6481 11.4216 78.9261 11.8997 79.9941 12.856C80.9541 13.7585 81.5961 14.9299 81.8061 16.4181C81.758 16.4181 70.3941 16.4181 70.3941 16.4181Z"
            fill="white"
          />
          <path d="M19.922 15.408H18.692V26.3036H19.922V15.408Z" fill="url(#paint0_linear_16347_4193)" />
          <path d="M35.606 9.40137H24.668V10.6266H35.606V9.40137Z" fill="url(#paint1_linear_16347_4193)" />
          <path d="M13.892 9.40137H2.95398V10.6266H13.892V9.40137Z" fill="url(#paint2_linear_16347_4193)" />
          <path
            d="M22.64 14.2366L23.498 13.3879L31.232 21.092L30.38 21.9407L22.64 14.2366Z"
            fill="url(#paint3_linear_16347_4193)"
          />
          <path
            d="M15.068 13.3879L15.92 14.2366L8.18 21.9407L7.328 21.092L15.068 13.3879Z"
            fill="url(#paint4_linear_16347_4193)"
          />
          <path
            d="M16.718 14.7686L17.84 15.2467L13.73 25.3474L12.614 24.8693L16.718 14.7686Z"
            fill="url(#paint5_linear_16347_4193)"
          />
          <path
            d="M24.08 12.587L24.512 11.4753L34.646 15.5096L34.22 16.6273L24.08 12.587Z"
            fill="url(#paint6_linear_16347_4193)"
          />
          <path
            d="M20.828 15.1927L21.95 14.7146L26.216 24.7615L25.148 25.2396L20.828 15.1927Z"
            fill="url(#paint7_linear_16347_4193)"
          />
          <path
            d="M14.102 11.5769L14.588 12.6946L4.50197 16.95L4.02197 15.8323L14.102 11.5769Z"
            fill="url(#paint8_linear_16347_4193)"
          />
          <path d="M61.112 9.2937H58.604V26.0884H61.112V9.2937Z" fill="white" />
          <path
            d="M44.786 3.65773C43.664 4.56022 43.13 5.94084 43.13 7.80559V26.0884H45.638V11.4753H48.464V9.24598H45.638V7.97294C45.638 6.80149 45.956 5.9528 46.544 5.42087C47.132 4.88894 48.038 4.62596 49.214 4.62596H49.532V2.2771H49.37C47.396 2.2771 45.902 2.75524 44.786 3.65773Z"
            fill="white"
          />
          <path d="M54.602 9.23999H52.094V26.0346H54.602V9.23999Z" fill="white" />
          <defs>
            <linearGradient
              id="paint0_linear_16347_4193"
              x1="19.355"
              y1="24.1323"
              x2="19.2598"
              y2="17.5578"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_16347_4193"
              x1="33.4515"
              y1="9.96317"
              x2="26.8517"
              y2="10.0597"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_16347_4193"
              x1="5.10914"
              y1="10.062"
              x2="11.7092"
              y2="9.96013"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_16347_4193"
              x1="29.3154"
              y1="19.9661"
              x2="24.5987"
              y2="15.3666"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_16347_4193"
              x1="9.29055"
              y1="20.0617"
              x2="13.8674"
              y2="15.3237"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_16347_4193"
              x1="13.9886"
              y1="23.2624"
              x2="16.3404"
              y2="17.1189"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_16347_4193"
              x1="32.4761"
              y1="15.2417"
              x2="26.321"
              y2="12.8671"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_16347_4193"
              x1="24.9352"
              y1="23.0815"
              x2="22.2704"
              y2="17.0661"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_16347_4193"
              x1="6.10595"
              y1="15.6796"
              x2="12.129"
              y2="12.9903"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <div className="flex flex-col justify-start items-start space-y-[24px] w-full h-[467px]">
          <div className="relative flex justify-start items-center gap-2">
            <div
              onClick={() => setIsModalOpen(!isModalOpen)}
              className="flex justify-start items-center cursor-pointer py-[8px] px-[24px] gap-[8px] w-[265px] h-[59px] rounded-[11.12px] bg-[#FFFFFF1F]"
            >
              <span className="w-[40px] h-[40px] rounded-[57.67px] flex justify-center items-center bg-[#4ECCA3]">
                <p>W</p>
              </span>
              <div className="flex flex-col justify-center items-start space-y-[8px] w-[137px] h-[43px]">
                <p className="font-albert font-bold text-[14px] leading-[16.8px] text-[#FFFFFF]">Waqas atta</p>
                <p className="font-albert font-normal text-[10px] leading-[12px] text-[#CCCCCC]">Admin</p>
              </div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 5.45801L15 12.458L9 19.458"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {isModalOpen && (
              <div
                ref={modalRef}
                className="flex flex-col justify-start items-start shadow-lg absolute -top-[10px] -right-40 rounded-[12px] p-[16px] gap-[8px] w-[177px] h-[82px] bg-white z-40"
              >
                <div
                  onClick={() => handleSettings()}
                  className="w-[145px] h-[21px] cursor-pointer flex justify-start items-center gap-[8px]"
                >
                  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 8C10.6569 8 12 6.65685 12 5C12 3.34315 10.6569 2 9 2C7.34315 2 6 3.34315 6 5C6 6.65685 7.34315 8 9 8Z"
                      stroke="#0F153E"
                      stroke-width="1.125"
                      stroke-linecap="round"
                    />
                    <path
                      d="M9 16.25C11.8995 16.25 14.25 14.9069 14.25 13.25C14.25 11.5931 11.8995 10.25 9 10.25C6.10051 10.25 3.75 11.5931 3.75 13.25C3.75 14.9069 6.10051 16.25 9 16.25Z"
                      stroke="#0F153E"
                      stroke-width="1.125"
                      stroke-linecap="round"
                    />
                  </svg>
                  <p className="font-poppins font-normal text-[14px] leading-[21px] text-[#000000]">Account Settings</p>
                </div>
                <div
                  onClick={() => handleLogout()}
                  className="w-[145px] h-[21px] cursor-pointer flex justify-start items-center gap-[8px]"
                >
                  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.75146 5.75C6.76055 4.11873 6.83288 3.2353 7.40917 2.65901C8.06818 2 9.12884 2 11.2502 2L12.0002 2C14.1215 2 15.1821 2 15.8412 2.65901C16.5002 3.31802 16.5002 4.37868 16.5002 6.5L16.5002 12.5C16.5002 14.6213 16.5002 15.682 15.8412 16.341C15.1821 17 14.1215 17 12.0002 17H11.2502C9.12884 17 8.06818 17 7.40917 16.341C6.83288 15.7647 6.76055 14.8813 6.75146 13.25"
                      stroke="#0F153E"
                      stroke-width="1.125"
                      stroke-linecap="round"
                    />
                    <path
                      d="M11.25 9.5L1.5 9.5M1.5 9.5L4.125 7.25M1.5 9.5L4.125 11.75"
                      stroke="#0F153E"
                      stroke-width="1.125"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p className="font-poppins font-normal text-[14px] leading-[21px] text-[#000000]">Log Out</p>
                </div>
              </div>
            )}
          </div>
          <NavLink
            to="/dashboard"
            onClick={() => {
              setIsDashboardSelected(true);
              setIsOffersSelected(true);
            }}
            className={({ isActive }) =>
              isActive
                ? 'flex justify-start items-center font-poppins font-normal  w-[265px] text-white h-[44px] bg-[#F1F3FF0D] border-r-[3px] border-[#FFFFFF] py-[12px] px-[32px] gap-[8px] text-[14px] leading-[21px]'
                : 'flex justify-start items-center font-poppins font-normal  w-[265px] border-b-[1px] border-[#FFFFFF0D]  h-[44px] py-[12px] px-[32px] gap-[8px] text-[#949494] text-[14px] leading-[21px]'
            }
          >
            {isDasboardSelected ? (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.5 3.45801H3.33333C2.8731 3.45801 2.5 3.8311 2.5 4.29134V10.1247C2.5 10.5849 2.8731 10.958 3.33333 10.958H7.5C7.96024 10.958 8.33333 10.5849 8.33333 10.1247V4.29134C8.33333 3.8311 7.96024 3.45801 7.5 3.45801Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6667 3.45801H12.5C12.0398 3.45801 11.6667 3.8311 11.6667 4.29134V6.79134C11.6667 7.25158 12.0398 7.62467 12.5 7.62467H16.6667C17.1269 7.62467 17.5 7.25158 17.5 6.79134V4.29134C17.5 3.8311 17.1269 3.45801 16.6667 3.45801Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6667 10.958H12.5C12.0398 10.958 11.6667 11.3311 11.6667 11.7913V17.6247C11.6667 18.0849 12.0398 18.458 12.5 18.458H16.6667C17.1269 18.458 17.5 18.0849 17.5 17.6247V11.7913C17.5 11.3311 17.1269 10.958 16.6667 10.958Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.5 14.2913H3.33333C2.8731 14.2913 2.5 14.6644 2.5 15.1246V17.6246C2.5 18.0848 2.8731 18.4579 3.33333 18.4579H7.5C7.96024 18.4579 8.33333 18.0848 8.33333 17.6246V15.1246C8.33333 14.6644 7.96024 14.2913 7.5 14.2913Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.5 3.45801H3.33333C2.8731 3.45801 2.5 3.8311 2.5 4.29134V10.1247C2.5 10.5849 2.8731 10.958 3.33333 10.958H7.5C7.96024 10.958 8.33333 10.5849 8.33333 10.1247V4.29134C8.33333 3.8311 7.96024 3.45801 7.5 3.45801Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6667 3.45801H12.5C12.0398 3.45801 11.6667 3.8311 11.6667 4.29134V6.79134C11.6667 7.25158 12.0398 7.62467 12.5 7.62467H16.6667C17.1269 7.62467 17.5 7.25158 17.5 6.79134V4.29134C17.5 3.8311 17.1269 3.45801 16.6667 3.45801Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6667 10.958H12.5C12.0398 10.958 11.6667 11.3311 11.6667 11.7913V17.6247C11.6667 18.0849 12.0398 18.458 12.5 18.458H16.6667C17.1269 18.458 17.5 18.0849 17.5 17.6247V11.7913C17.5 11.3311 17.1269 10.958 16.6667 10.958Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.5 14.2913H3.33333C2.8731 14.2913 2.5 14.6644 2.5 15.1246V17.6246C2.5 18.0848 2.8731 18.4579 3.33333 18.4579H7.5C7.96024 18.4579 8.33333 18.0848 8.33333 17.6246V15.1246C8.33333 14.6644 7.96024 14.2913 7.5 14.2913Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            Dashboard
          </NavLink>
          <NavLink
            to="/offers"
            onClick={() => {
              setIsDashboardSelected(false);
              setIsOffersSelected(true);
            }}
            className={({ isActive }) =>
              isActive
                ? 'flex justify-start items-center font-poppins font-normal  w-[265px] text-white h-[44px] bg-[#F1F3FF0D] border-r-[3px] border-[#FFFFFF] py-[12px] px-[32px] gap-[8px] text-[14px] leading-[21px]'
                : 'flex justify-start items-center font-poppins font-normal  w-[265px] border-b-[1px] border-[#FFFFFF0D]  h-[44px] py-[12px] px-[32px] gap-[8px] text-[#949494] text-[14px] leading-[21px]'
            }
          >
            {isOffersSelected ? (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.20831 8.14134C3.08668 7.59345 3.10536 7.02371 3.26261 6.48496C3.41986 5.9462 3.7106 5.45588 4.10787 5.05944C4.50514 4.66301 4.99607 4.37329 5.53515 4.21717C6.07423 4.06105 6.64401 4.04356 7.19164 4.16634C7.49307 3.69493 7.90831 3.30697 8.3991 3.03825C8.88989 2.76952 9.44043 2.62866 9.99998 2.62866C10.5595 2.62866 11.1101 2.76952 11.6009 3.03825C12.0916 3.30697 12.5069 3.69493 12.8083 4.16634C13.3568 4.04303 13.9275 4.06043 14.4675 4.21694C15.0074 4.37344 15.499 4.66396 15.8965 5.06147C16.294 5.45898 16.5845 5.95057 16.741 6.49051C16.8976 7.03045 16.915 7.6012 16.7916 8.14967C17.2631 8.4511 17.651 8.86634 17.9197 9.35713C18.1885 9.84792 18.3293 10.3985 18.3293 10.958C18.3293 11.5176 18.1885 12.0681 17.9197 12.5589C17.651 13.0497 17.2631 13.4649 16.7916 13.7663C16.9144 14.314 16.8969 14.8838 16.7408 15.4228C16.5847 15.9619 16.295 16.4529 15.8985 16.8501C15.5021 17.2474 15.0118 17.5381 14.473 17.6954C13.9343 17.8526 13.3645 17.8713 12.8166 17.7497C12.5156 18.2229 12.1 18.6125 11.6084 18.8824C11.1168 19.1524 10.565 19.2939 10.0041 19.2939C9.44329 19.2939 8.8915 19.1524 8.39987 18.8824C7.90825 18.6125 7.49268 18.2229 7.19164 17.7497C6.64401 17.8725 6.07423 17.855 5.53515 17.6988C4.99607 17.5427 4.50514 17.253 4.10787 16.8566C3.7106 16.4601 3.41986 15.9698 3.26261 15.4311C3.10536 14.8923 3.08668 14.3226 3.20831 13.7747C2.73327 13.474 2.34199 13.0582 2.07085 12.5657C1.79971 12.0732 1.65753 11.5202 1.65753 10.958C1.65753 10.3958 1.79971 9.84279 2.07085 9.35033C2.34199 8.85786 2.73327 8.44197 3.20831 8.14134Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.3334 7.62476H8.33335C7.89133 7.62476 7.4674 7.80035 7.15484 8.11291C6.84228 8.42547 6.66669 8.84939 6.66669 9.29142C6.66669 9.73345 6.84228 10.1574 7.15484 10.4699C7.4674 10.7825 7.89133 10.9581 8.33335 10.9581H11.6667C12.1087 10.9581 12.5326 11.1337 12.8452 11.4462C13.1578 11.7588 13.3334 12.1827 13.3334 12.6248C13.3334 13.0668 13.1578 13.4907 12.8452 13.8033C12.5326 14.1158 12.1087 14.2914 11.6667 14.2914H6.66669"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M10 15.958V5.95801" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            ) : (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.20831 8.14134C3.08668 7.59345 3.10536 7.02371 3.26261 6.48496C3.41986 5.9462 3.7106 5.45588 4.10787 5.05944C4.50514 4.66301 4.99607 4.37329 5.53515 4.21717C6.07423 4.06105 6.64401 4.04356 7.19164 4.16634C7.49307 3.69493 7.90831 3.30697 8.3991 3.03825C8.88989 2.76952 9.44043 2.62866 9.99998 2.62866C10.5595 2.62866 11.1101 2.76952 11.6009 3.03825C12.0916 3.30697 12.5069 3.69493 12.8083 4.16634C13.3568 4.04303 13.9275 4.06043 14.4675 4.21694C15.0074 4.37344 15.499 4.66396 15.8965 5.06147C16.294 5.45898 16.5845 5.95057 16.741 6.49051C16.8976 7.03045 16.915 7.6012 16.7916 8.14967C17.2631 8.4511 17.651 8.86634 17.9197 9.35713C18.1885 9.84792 18.3293 10.3985 18.3293 10.958C18.3293 11.5176 18.1885 12.0681 17.9197 12.5589C17.651 13.0497 17.2631 13.4649 16.7916 13.7663C16.9144 14.314 16.8969 14.8838 16.7408 15.4228C16.5847 15.9619 16.295 16.4529 15.8985 16.8501C15.5021 17.2474 15.0118 17.5381 14.473 17.6954C13.9343 17.8526 13.3645 17.8713 12.8166 17.7497C12.5156 18.2229 12.1 18.6125 11.6084 18.8824C11.1168 19.1524 10.565 19.2939 10.0041 19.2939C9.44329 19.2939 8.8915 19.1524 8.39987 18.8824C7.90825 18.6125 7.49268 18.2229 7.19164 17.7497C6.64401 17.8725 6.07423 17.855 5.53515 17.6988C4.99607 17.5427 4.50514 17.253 4.10787 16.8566C3.7106 16.4601 3.41986 15.9698 3.26261 15.4311C3.10536 14.8923 3.08668 14.3226 3.20831 13.7747C2.73327 13.474 2.34199 13.0582 2.07085 12.5657C1.79971 12.0732 1.65753 11.5202 1.65753 10.958C1.65753 10.3958 1.79971 9.84279 2.07085 9.35033C2.34199 8.85786 2.73327 8.44197 3.20831 8.14134Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.3334 7.62476H8.33335C7.89133 7.62476 7.4674 7.80035 7.15484 8.11291C6.84228 8.42547 6.66669 8.84939 6.66669 9.29142C6.66669 9.73345 6.84228 10.1574 7.15484 10.4699C7.4674 10.7825 7.89133 10.9581 8.33335 10.9581H11.6667C12.1087 10.9581 12.5326 11.1337 12.8452 11.4462C13.1578 11.7588 13.3334 12.1827 13.3334 12.6248C13.3334 13.0668 13.1578 13.4907 12.8452 13.8033C12.5326 14.1158 12.1087 14.2914 11.6667 14.2914H6.66669"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M10 15.958V5.95801" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            )}
            Offers
          </NavLink>
          <NavLink
            to="/clients"
            onClick={() => {
              setIsClientsSelected(true);
              setIsDashboardSelected(false);
              setIsOffersSelected(false);
            }}
            className={({ isActive }) =>
              isActive
                ? 'flex justify-start items-center font-poppins font-normal  w-[265px] text-white h-[44px] bg-[#F1F3FF0D] border-r-[3px] border-[#FFFFFF] py-[12px] px-[32px] gap-[8px] text-[14px] leading-[21px]'
                : 'flex justify-start items-center font-poppins font-normal  w-[265px] border-b-[1px] border-[#FFFFFF0D]  h-[44px] py-[12px] px-[32px] gap-[8px] text-[#949494] text-[14px] leading-[21px]'
            }
          >
            {isClientsSelected ? (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 2.62476V4.29142" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M5.83331 19.2913V17.6247C5.83331 17.1826 6.00891 16.7587 6.32147 16.4462C6.63403 16.1336 7.05795 15.958 7.49998 15.958H12.5C12.942 15.958 13.3659 16.1336 13.6785 16.4462C13.9911 16.7587 14.1666 17.1826 14.1666 17.6247V19.2913"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M6.66669 2.62476V4.29142" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M10 12.6248C11.3807 12.6248 12.5 11.5055 12.5 10.1248C12.5 8.74404 11.3807 7.62476 10 7.62476C8.61929 7.62476 7.5 8.74404 7.5 10.1248C7.5 11.5055 8.61929 12.6248 10 12.6248Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.8333 4.29126H4.16667C3.24619 4.29126 2.5 5.03745 2.5 5.95793V17.6246C2.5 18.5451 3.24619 19.2913 4.16667 19.2913H15.8333C16.7538 19.2913 17.5 18.5451 17.5 17.6246V5.95793C17.5 5.03745 16.7538 4.29126 15.8333 4.29126Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 2.62476V4.29142" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M5.83331 19.2913V17.6247C5.83331 17.1826 6.00891 16.7587 6.32147 16.4462C6.63403 16.1336 7.05795 15.958 7.49998 15.958H12.5C12.942 15.958 13.3659 16.1336 13.6785 16.4462C13.9911 16.7587 14.1666 17.1826 14.1666 17.6247V19.2913"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M6.66669 2.62476V4.29142" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M10 12.6248C11.3807 12.6248 12.5 11.5055 12.5 10.1248C12.5 8.74404 11.3807 7.62476 10 7.62476C8.61929 7.62476 7.5 8.74404 7.5 10.1248C7.5 11.5055 8.61929 12.6248 10 12.6248Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.8333 4.29126H4.16667C3.24619 4.29126 2.5 5.03745 2.5 5.95793V17.6246C2.5 18.5451 3.24619 19.2913 4.16667 19.2913H15.8333C16.7538 19.2913 17.5 18.5451 17.5 17.6246V5.95793C17.5 5.03745 16.7538 4.29126 15.8333 4.29126Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            Clients
          </NavLink>
          <NavLink
            to="/products"
            onClick={() => {
              setIsProductsSelected(true);
              setIsClientsSelected(false);
              setIsDashboardSelected(false);
              setIsOffersSelected(false);
            }}
            className={({ isActive }) =>
              isActive
                ? 'flex justify-start items-center font-poppins font-normal  w-[265px] text-white h-[44px] bg-[#F1F3FF0D] border-r-[3px] border-[#FFFFFF] py-[12px] px-[32px] gap-[8px] text-[14px] leading-[21px]'
                : 'flex justify-start items-center font-poppins font-normal  w-[265px] border-b-[1px] border-[#FFFFFF0D]  h-[44px] py-[12px] px-[32px] gap-[8px] text-[#949494] text-[14px] leading-[21px]'
            }
          >
            {isProductsSelected ? (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.5 7.62476C17.4997 7.33248 17.4225 7.04543 17.2763 6.79239C17.13 6.53935 16.9198 6.32922 16.6667 6.18309L10.8333 2.84976C10.58 2.70348 10.2926 2.62646 10 2.62646C9.70744 2.62646 9.42003 2.70348 9.16667 2.84976L3.33333 6.18309C3.08022 6.32922 2.86998 6.53935 2.72372 6.79239C2.57745 7.04543 2.5003 7.33248 2.5 7.62476V14.2914C2.5003 14.5837 2.57745 14.8707 2.72372 15.1238C2.86998 15.3768 3.08022 15.587 3.33333 15.7331L9.16667 19.0664C9.42003 19.2127 9.70744 19.2897 10 19.2897C10.2926 19.2897 10.58 19.2127 10.8333 19.0664L16.6667 15.7331C16.9198 15.587 17.13 15.3768 17.2763 15.1238C17.4225 14.8707 17.4997 14.5837 17.5 14.2914V7.62476Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.75 6.79126L10 10.9579L17.25 6.79126"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M10 19.2913V10.958" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            ) : (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.5 7.62476C17.4997 7.33248 17.4225 7.04543 17.2763 6.79239C17.13 6.53935 16.9198 6.32922 16.6667 6.18309L10.8333 2.84976C10.58 2.70348 10.2926 2.62646 10 2.62646C9.70744 2.62646 9.42003 2.70348 9.16667 2.84976L3.33333 6.18309C3.08022 6.32922 2.86998 6.53935 2.72372 6.79239C2.57745 7.04543 2.5003 7.33248 2.5 7.62476V14.2914C2.5003 14.5837 2.57745 14.8707 2.72372 15.1238C2.86998 15.3768 3.08022 15.587 3.33333 15.7331L9.16667 19.0664C9.42003 19.2127 9.70744 19.2897 10 19.2897C10.2926 19.2897 10.58 19.2127 10.8333 19.0664L16.6667 15.7331C16.9198 15.587 17.13 15.3768 17.2763 15.1238C17.4225 14.8707 17.4997 14.5837 17.5 14.2914V7.62476Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.75 6.79126L10 10.9579L17.25 6.79126"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M10 19.2913V10.958" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            )}
            Products
          </NavLink>
          <NavLink
            to="/team"
            onClick={() => {
              setIsProductsSelected(true);
              setIsClientsSelected(false);
              setIsDashboardSelected(false);
              setIsOffersSelected(false);
            }}
            className={({ isActive }) =>
              isActive
                ? 'flex justify-start items-center font-poppins font-normal  w-[265px] text-white h-[44px] bg-[#F1F3FF0D] border-r-[3px] border-[#FFFFFF] py-[12px] px-[32px] gap-[8px] text-[14px] leading-[21px]'
                : 'flex justify-start items-center font-poppins font-normal  w-[265px] border-b-[1px] border-[#FFFFFF0D]  h-[44px] py-[12px] px-[32px] gap-[8px] text-[#949494] text-[14px] leading-[21px]'
            }
          >
            {isTeamSelected ? (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 18.4579C15 16.6898 14.2976 14.9941 13.0474 13.7439C11.7972 12.4936 10.1015 11.7913 8.33335 11.7913C6.56524 11.7913 4.86955 12.4936 3.61931 13.7439C2.36907 14.9941 1.66669 16.6898 1.66669 18.4579"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.33335 11.7913C10.6345 11.7913 12.5 9.92586 12.5 7.62467C12.5 5.32349 10.6345 3.45801 8.33335 3.45801C6.03217 3.45801 4.16669 5.32349 4.16669 7.62467C4.16669 9.92586 6.03217 11.7913 8.33335 11.7913Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.3333 17.6246C18.3333 14.8163 16.6667 12.2079 15 10.9579C15.5478 10.5469 15.9859 10.0072 16.2755 9.38648C16.565 8.7658 16.6971 8.08332 16.66 7.39943C16.6229 6.71554 16.4178 6.05133 16.0629 5.46559C15.7079 4.87985 15.2141 4.39064 14.625 4.04126"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 18.4579C15 16.6898 14.2976 14.9941 13.0474 13.7439C11.7972 12.4936 10.1015 11.7913 8.33335 11.7913C6.56524 11.7913 4.86955 12.4936 3.61931 13.7439C2.36907 14.9941 1.66669 16.6898 1.66669 18.4579"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.33335 11.7913C10.6345 11.7913 12.5 9.92586 12.5 7.62467C12.5 5.32349 10.6345 3.45801 8.33335 3.45801C6.03217 3.45801 4.16669 5.32349 4.16669 7.62467C4.16669 9.92586 6.03217 11.7913 8.33335 11.7913Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.3333 17.6246C18.3333 14.8163 16.6667 12.2079 15 10.9579C15.5478 10.5469 15.9859 10.0072 16.2755 9.38648C16.565 8.7658 16.6971 8.08332 16.66 7.39943C16.6229 6.71554 16.4178 6.05133 16.0629 5.46559C15.7079 4.87985 15.2141 4.39064 14.625 4.04126"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            Team
          </NavLink>
          <NavLink
            to="/admin"
            onClick={() => {
              setIsProductsSelected(false);
              setIsClientsSelected(false);
              setIsDashboardSelected(false);
              setIsOffersSelected(false);
              setIsAdminSeleted(true);
            }}
            className={({ isActive }) =>
              isActive
                ? 'flex justify-start items-center font-poppins font-normal  w-[265px] text-white h-[44px] bg-[#F1F3FF0D] border-r-[3px] border-[#FFFFFF] py-[12px] px-[32px] gap-[8px] text-[14px] leading-[21px]'
                : 'flex justify-start items-center font-poppins font-normal  w-[265px] border-b-[1px] border-[#FFFFFF0D]  h-[44px] py-[12px] px-[32px] gap-[8px] text-[#949494] text-[14px] leading-[21px]'
            }
          >
            {isAdminSelected ? (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.5 3.45801H3.33333C2.8731 3.45801 2.5 3.8311 2.5 4.29134V10.1247C2.5 10.5849 2.8731 10.958 3.33333 10.958H7.5C7.96024 10.958 8.33333 10.5849 8.33333 10.1247V4.29134C8.33333 3.8311 7.96024 3.45801 7.5 3.45801Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6667 3.45801H12.5C12.0398 3.45801 11.6667 3.8311 11.6667 4.29134V6.79134C11.6667 7.25158 12.0398 7.62467 12.5 7.62467H16.6667C17.1269 7.62467 17.5 7.25158 17.5 6.79134V4.29134C17.5 3.8311 17.1269 3.45801 16.6667 3.45801Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6667 10.958H12.5C12.0398 10.958 11.6667 11.3311 11.6667 11.7913V17.6247C11.6667 18.0849 12.0398 18.458 12.5 18.458H16.6667C17.1269 18.458 17.5 18.0849 17.5 17.6247V11.7913C17.5 11.3311 17.1269 10.958 16.6667 10.958Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.5 14.2913H3.33333C2.8731 14.2913 2.5 14.6644 2.5 15.1246V17.6246C2.5 18.0848 2.8731 18.4579 3.33333 18.4579H7.5C7.96024 18.4579 8.33333 18.0848 8.33333 17.6246V15.1246C8.33333 14.6644 7.96024 14.2913 7.5 14.2913Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.5 3.45801H3.33333C2.8731 3.45801 2.5 3.8311 2.5 4.29134V10.1247C2.5 10.5849 2.8731 10.958 3.33333 10.958H7.5C7.96024 10.958 8.33333 10.5849 8.33333 10.1247V4.29134C8.33333 3.8311 7.96024 3.45801 7.5 3.45801Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6667 3.45801H12.5C12.0398 3.45801 11.6667 3.8311 11.6667 4.29134V6.79134C11.6667 7.25158 12.0398 7.62467 12.5 7.62467H16.6667C17.1269 7.62467 17.5 7.25158 17.5 6.79134V4.29134C17.5 3.8311 17.1269 3.45801 16.6667 3.45801Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6667 10.958H12.5C12.0398 10.958 11.6667 11.3311 11.6667 11.7913V17.6247C11.6667 18.0849 12.0398 18.458 12.5 18.458H16.6667C17.1269 18.458 17.5 18.0849 17.5 17.6247V11.7913C17.5 11.3311 17.1269 10.958 16.6667 10.958Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.5 14.2913H3.33333C2.8731 14.2913 2.5 14.6644 2.5 15.1246V17.6246C2.5 18.0848 2.8731 18.4579 3.33333 18.4579H7.5C7.96024 18.4579 8.33333 18.0848 8.33333 17.6246V15.1246C8.33333 14.6644 7.96024 14.2913 7.5 14.2913Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            Admin
          </NavLink>
          <NavLink
            to="/device-management"
            onClick={() => {
              setIsProductsSelected(false);
              setIsClientsSelected(false);
              setIsDashboardSelected(false);
              setIsOffersSelected(false);
              setIsAdminSeleted(false);
              setIsDeviceSeleted(true);
            }}
            className={({ isActive }) =>
              isActive
                ? 'flex justify-start items-center font-poppins font-normal  w-[265px] text-white h-[44px] bg-[#F1F3FF0D] border-r-[3px] border-[#FFFFFF] py-[12px] px-[32px] gap-[8px] text-[14px] leading-[21px]'
                : 'flex justify-start items-center font-poppins font-normal  w-[265px] border-b-[1px] border-[#FFFFFF0D]  h-[44px] py-[12px] px-[32px] gap-[8px] text-[#949494] text-[14px] leading-[21px]'
            }
          >
            {isDeviceSelected ? (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.9998 7.62472V5.95805C14.9998 5.51602 14.8242 5.0921 14.5117 4.77954C14.1991 4.46698 13.7752 4.29138 13.3332 4.29138H3.33317C2.89114 4.29138 2.46722 4.46698 2.15466 4.77954C1.8421 5.0921 1.6665 5.51602 1.6665 5.95805V11.7914C1.6665 12.2334 1.8421 12.6573 2.15466 12.9699C2.46722 13.2825 2.89114 13.458 3.33317 13.458H9.99984"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.3335 16.7913V13.4913V16.1163"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M5.8335 16.7914H10.0002" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M16.6668 10.958H15.0002C14.0797 10.958 13.3335 11.7042 13.3335 12.6247V17.6247C13.3335 18.5451 14.0797 19.2913 15.0002 19.2913H16.6668C17.5873 19.2913 18.3335 18.5451 18.3335 17.6247V12.6247C18.3335 11.7042 17.5873 10.958 16.6668 10.958Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.9998 7.62472V5.95805C14.9998 5.51602 14.8242 5.0921 14.5117 4.77954C14.1991 4.46698 13.7752 4.29138 13.3332 4.29138H3.33317C2.89114 4.29138 2.46722 4.46698 2.15466 4.77954C1.8421 5.0921 1.6665 5.51602 1.6665 5.95805V11.7914C1.6665 12.2334 1.8421 12.6573 2.15466 12.9699C2.46722 13.2825 2.89114 13.458 3.33317 13.458H9.99984"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.3335 16.7913V13.4913V16.1163"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M5.8335 16.7914H10.0002" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M16.6668 10.958H15.0002C14.0797 10.958 13.3335 11.7042 13.3335 12.6247V17.6247C13.3335 18.5451 14.0797 19.2913 15.0002 19.2913H16.6668C17.5873 19.2913 18.3335 18.5451 18.3335 17.6247V12.6247C18.3335 11.7042 17.5873 10.958 16.6668 10.958Z"
                  stroke="#CCCCCC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            Device Management
          </NavLink>
        </div>
      </div>
      <div className="flex justify-start items-center gap-[8px] bg-[#FFFFFF1F] w-[265px] h-[52px] p-[24px]">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_16347_4277)">
            <path
              d="M4.16669 7.62476L9.16669 12.6248"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.33331 12.6248L8.33331 7.62476L9.99998 5.12476"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.66669 5.12476H11.6667"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.83331 2.62476H6.66665"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.3333 19.2913L14.1667 10.958L10 19.2913"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.6667 15.958H16.6667"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_16347_4277">
              <rect width="20" height="20" fill="white" transform="translate(0 0.958008)" />
            </clipPath>
          </defs>
        </svg>
        <p className="w-[157px] h-[17px] font-albert font-normal text-[14px] leading-[16.8px] text-[#FFFFFF]">
          English
        </p>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 15.958L12 9.95801L19 15.958"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default Sidebar;