import { ErrorBoundary } from '@sentry/react';
import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { AuthRoute } from '../components/AuthRoute/AuthRoute';
import { Routes } from './Routes';
import NewDashboard from './NewDashboard/NewDashboard';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import Profile from './Profile/Profile';
import Offers from './Offers/Offers';
import Clients from './Clients/Clients';
import Products from './Products/Products';
import AddNewOffer from './AddNewOffer/AddNewOffer';
import AddNewClient from './AddNewClient/AddNewClient';
import AddNewProduct from './AddNewProduct/AddNewProduct';
import Team from './Team/Team';
import DeviceManagement from './DeviceManagement/DeviceManagement';
import Admin from './Admin/Admin';
import UpdateProduct from './UpdateNewProduct/UpdateNewProduct';

const BackOffice = lazy(() => import('./BackOffice/BackOffice'));
const CheckIn = lazy(() => import('./CheckIn/CheckIn'));
const CheckInQuotationShow = lazy(() => import('./CheckIn/components/QuotationShow/QuotationShow'));
const CheckOut = lazy(() => import('./CheckOut/CheckOut'));
const ForgotPassword = lazy(() => import('./Auth/ForgotPassword/ForgotPassword'));
const NotFound = lazy(() => import('./NotFound/NotFound'));
const SignIn = lazy(() => import('./Auth/SignIn/SignIn'));
const Register = lazy(() => import('./Auth/Register/Register'));
const ResetPassword = lazy(() => import('./Auth/ResetPassword/ResetPassword'));
const ErrorPage = lazy(() => import('./Error/Error'));
const AutoCreateQuotation = lazy(() => import('./CheckIn/components/AutoCreateQuotation/AutoCreateQuotation'));

export default function Pages(): JSX.Element {
  return (
    <ErrorBoundary showDialog>
      <Switch>
        <Route exact path={Routes.Home}>
          <Redirect to={Routes.BackOffice} />
        </Route>
        {/* New Dashboard Routes, Public for now */}
        <Route exact path={Routes.Dashboard}>
          <DashboardLayout>
            <NewDashboard />
          </DashboardLayout>
        </Route>
        <Route path={Routes.Profile}>
          <DashboardLayout>
            <Profile />
          </DashboardLayout>
        </Route>
        <Route path={Routes.Offers}>
          <DashboardLayout>
            <Offers />
          </DashboardLayout>
        </Route>
        <Route path={Routes.AddNewOffer}>
          <DashboardLayout>
            <AddNewOffer />
          </DashboardLayout>
        </Route>
        <Route path={Routes.Clients}>
          <DashboardLayout>
            <Clients />
          </DashboardLayout>
        </Route>
        <Route path={Routes.AddNewClient}>
          <DashboardLayout>
            <AddNewClient />
          </DashboardLayout>
        </Route>
        <Route path={Routes.Products}>
          <DashboardLayout>
            <Products />
          </DashboardLayout>
        </Route>
        <Route path={Routes.UpdateProduct}>
          <DashboardLayout>
            <UpdateProduct />
          </DashboardLayout>
        </Route>
        <Route path={Routes.AddNewProduct}>
          <DashboardLayout>
            <AddNewProduct />
          </DashboardLayout>
        </Route>
        <Route path={Routes.Team}>
          <DashboardLayout>
            <Team />
          </DashboardLayout>
        </Route>
        <Route path={Routes.Admin}>
          <DashboardLayout>
            <Admin />
          </DashboardLayout>
        </Route>
        <Route path={Routes.DeviceManagement}>
          <DashboardLayout>
            <DeviceManagement />
          </DashboardLayout>
        </Route>
        {/* New Dashboard Routes, Public for now */}
        <AuthRoute mode="guest" path={Routes.Register}>
          <Register />
        </AuthRoute>
        <AuthRoute mode="guest" path={Routes.SignIn}>
          <SignIn />
        </AuthRoute>
        <AuthRoute mode="guest" path={Routes.ForgotPassword}>
          <ForgotPassword />
        </AuthRoute>
        <AuthRoute mode="guest" path={Routes.ResetPassword}>
          <ResetPassword />
        </AuthRoute>
        <AuthRoute mode="private" path={Routes.BackOffice}>
          <BackOffice />
        </AuthRoute>
        <AuthRoute mode="private" path={Routes.CheckInQuotationShow}>
          <CheckInQuotationShow />
        </AuthRoute>
        <AuthRoute mode="private" path={Routes.CheckInQuotation}>
          <CheckIn />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.CheckIn}>
          <AutoCreateQuotation />
        </AuthRoute>
        <AuthRoute mode="private" path={Routes.CheckOut}>
          <CheckOut />
        </AuthRoute>
        <Route path={Routes.Error}>
          <ErrorPage />
        </Route>
        <Route path={Routes.NotFound}>
          <NotFound />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}
